export const INCREMENT_ACTIVE_TAB_ID = 'INCREMENT_ACTIVE_TAB_ID';
export const DECREMENT_ACTIVE_TAB_ID = 'DECREMENT_ACTIVE_TAB_ID';
export const CHANGE_ACTIVE_TAB_ID = 'CHANGE_ACTIVE_TAB_ID';

export const ADD_DETAILS = 'ADD_DETAILS';
export const SAVE_DETAILS = 'SAVE_DETAILS';

export const CREATE_MILESTONE = 'CREATE_MILESTONE';
export const DELETE_MILESTONE = 'DELETE_MILESTONE';
export const UPDATE_MILESTONE = 'UPDATE_MILESTONE';

export const CREATE_BUDGET = 'CREATE_BUDGET';
export const DELETE_BUDGET = 'DELETE_BUDGET';
export const UPDATE_BUDGET = 'UPDATE_BUDGET';

export const CREATE_PAYMENT_GROUP = 'CREATE_PAYMENT_GROUP';
export const DELETE_PAYMENT_GROUP = 'DELETE_PAYMENT_GROUP';
export const UPDATE_PAYMENT_GROUP = 'UPDATE_PAYMENT_GROUP';

export const SAVE_DRAFT_PROPOSAL = 'SAVE_DRAFT_PROPOSAL';
export const SAVE_DRAFT_PROPOSAL_LOADING = 'SAVE_DRAFT_PROPOSAL_LOADING';
export const SAVE_DRAFT_PROPOSAL_SUCCESS = 'SAVE_DRAFT_PROPOSAL_SUCCESS';
export const SAVE_DRAFT_PROPOSAL_FAILURE = 'SAVE_DRAFT_PROPOSAL_FAILURE';
export const RESET_PROPOSAL = 'RESET_PROPOSAL';

export const DELETE_PROPOSAL_IMAGE = 'DELETE_PROPOSAL_IMAGE';
export const DELETE_PROPOSAL_IMAGE_SUCCESS = 'DELETE_PROPOSAL_IMAGE_SUCCESS';

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

export const GET_PROJECT_TYPES = 'GET_PROJECT_TYPES';
export const GET_PROJECT_TYPES_LOADING = 'GET_PROJECT_TYPES_LOADING';
export const GET_PROJECT_TYPES_SUCCESS = 'GET_PROJECT_TYPES_SUCCESS';
export const GET_PROJECT_TYPES_FAILURE = 'GET_PROJECT_TYPES_FAILURE';

export const GET_CONTRACTORS_LIST_LOADING = 'GET_CONTRACTORS_LIST_LOADING';
export const GET_CONTRACTORS_LIST = 'GET_CONTRACTORS_LIST';
export const GET_CONTRACTORS_LIST_SUCCESS = 'GET_CONTRACTORS_LIST_SUCCESS';
export const RESET_CONTRACTORS_LIST = 'RESET_CONTRACTORS_LIST';

export const ASSIGN_CONTRACTOR = 'ASSIGN_CONTRACTOR';
export const ASSIGN_CONTRACTOR_LOADING = 'ASSIGN_CONTRACTOR_LOADING';
export const ASSIGN_CONTRACTOR_SUCCESS = 'ASSIGN_CONTRACTOR_SUCCESS';
export const ASSIGN_CONTRACTOR_FAILURE = 'ASSIGN_CONTRACTOR_FAILURE';
export const RESET_ASSIGN_CONTRACTOR_SUCCESS_MESSAGE =
  'RESET_ASSIGN_CONTRACTOR_SUCCESS_MESSAGE';
export const SUBMIT_ASSIGN_CONTRACTOR = 'SUBMIT_ASSIGN_CONTRACTOR';

export const GET_PLANS = 'GET_PLANS';
export const SET_CALCULATION_STATUS = 'SET_CALCULATION_STATUS';
export const FETCH_PROPOSAL_FILES = 'FETCH_PROPOSAL_FILES';
export const DELETE_PROJECT_FILE = 'DELETE_PROJECT_FILE';

export const incrementActiveTabId = () => ({
  type: INCREMENT_ACTIVE_TAB_ID,
});

export const decrementActiveTabId = () => ({
  type: DECREMENT_ACTIVE_TAB_ID,
});

export const changeActiveTabId = (tabId) => ({
  type: CHANGE_ACTIVE_TAB_ID,
  payload: tabId,
});

export const addDetails = (details) => ({
  type: ADD_DETAILS,
  payload: details,
});

export const saveDetails = (details) => ({
  type: SAVE_DETAILS,
  payload: details,
});

export const createMilestone = (milestone) => ({
  type: CREATE_MILESTONE,
  payload: milestone,
});

export const deleteMilestone = (milestoneId) => ({
  type: DELETE_MILESTONE,
  payload: milestoneId,
});

export const updateMilestone = (milestone) => ({
  type: UPDATE_MILESTONE,
  payload: milestone,
});

export const createBudget = (budget) => ({
  type: CREATE_BUDGET,
  payload: budget,
});

export const deleteBudget = (budgetId) => ({
  type: DELETE_BUDGET,
  payload: budgetId,
});

export const updateBudget = (budget) => ({
  type: UPDATE_BUDGET,
  payload: budget,
});

export const createPaymentGroup = (paymentGroup) => ({
  type: CREATE_PAYMENT_GROUP,
  payload: paymentGroup,
});

export const deletePaymentGroup = (paymentGroupId) => ({
  type: DELETE_PAYMENT_GROUP,
  payload: paymentGroupId,
});

export const updatePaymentGroup = (paymentGroup) => ({
  type: UPDATE_PAYMENT_GROUP,
  payload: paymentGroup,
});

export const saveDraftProposal = (isDone) => ({
  type: SAVE_DRAFT_PROPOSAL,
  payload: isDone,
});

export const saveDraftProposalLoading = () => ({
  type: SAVE_DRAFT_PROPOSAL_LOADING,
});

export const saveDraftProposalSuccess = (proposal) => ({
  type: SAVE_DRAFT_PROPOSAL_SUCCESS,
  payload: proposal,
});

export const saveDraftProposalFailure = (errorMessage) => ({
  type: SAVE_DRAFT_PROPOSAL_FAILURE,
  payload: errorMessage,
});

export const resetProposal = () => ({
  type: RESET_PROPOSAL,
});

export const deleteProposalImage = (id) => ({
  type: DELETE_PROPOSAL_IMAGE,
  payload: id,
});
export const deleteProjectFile = ({
  fileId,
  successCallback,
  errorCallback,
}) => ({
  type: DELETE_PROJECT_FILE,
  payload: { fileId, successCallback, errorCallback },
});

export const deleteProposalImageSuccess = (id) => ({
  type: DELETE_PROPOSAL_IMAGE_SUCCESS,
  payload: id,
});

export const setInitialState = (proposal) => ({
  type: SET_INITIAL_STATE,
  payload: proposal,
});

export const getContractorsListLoading = () => ({
  type: GET_CONTRACTORS_LIST_LOADING,
});

export const getContractorsList = (projectId) => ({
  type: GET_CONTRACTORS_LIST,
  payload: projectId,
});

export const getContractorsListSuccess = (data) => ({
  type: GET_CONTRACTORS_LIST_SUCCESS,
  payload: data,
});

export const assignedContractors = (data) => ({
  type: ASSIGN_CONTRACTOR,
  payload: data,
});

export const submitAndAssignedContractors = (data) => ({
  type: SUBMIT_ASSIGN_CONTRACTOR,
  payload: data,
});

export const assignedContractorsLoading = () => ({
  type: ASSIGN_CONTRACTOR_LOADING,
});

export const assignedContractorsSuccess = (data) => ({
  type: ASSIGN_CONTRACTOR_SUCCESS,
  payload: data,
});

export const assignedContractorsFailure = (data) => ({
  type: ASSIGN_CONTRACTOR_FAILURE,
  payload: data,
});

export const resetAssignContractorsSuccessMessage = () => ({
  type: RESET_ASSIGN_CONTRACTOR_SUCCESS_MESSAGE,
});

export const resetContractorsList = () => ({
  type: RESET_CONTRACTORS_LIST,
});

export const getProjectTypes = () => ({
  type: GET_PROJECT_TYPES,
});

export const getProjectTypesLoading = () => ({
  type: GET_PROJECT_TYPES_LOADING,
});

export const getProjectTypesSuccess = (projectTypes) => ({
  type: GET_PROJECT_TYPES_SUCCESS,
  payload: projectTypes,
});

export const getProjectTypesFailure = (errorMessage) => ({
  type: GET_PROJECT_TYPES_FAILURE,
  payload: errorMessage,
});

export const getPlans = ({ data, successCallback, errorCallback }) => ({
  type: GET_PLANS,
  payload: { data, successCallback, errorCallback },
});

export const fetchProposalFiles = ({
  data,
  successCallback,
  errorCallback,
}) => ({
  type: FETCH_PROPOSAL_FILES,
  payload: { data, successCallback, errorCallback },
});

export const setCalculationStatus = (value) => ({
  type: SET_CALCULATION_STATUS,
  payload: value,
});
