import OptionsMenu from '@iso/containers/CreateProposal/OptionsMenu';
import { Menu } from '@iso/containers/CreateProposal/styles';
import {
  BudgetContent,
  BudgetDetailsDivider,
  OptionMenuButton,
} from '@iso/containers/CreateProposal/Budgets/styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Collapse, Space } from 'antd';
import React from 'react';
import {
  formatEmptyValue,
  formattedAmount,
  formatAmount,
} from '@iso/lib/helpers/utility';
import {
  BudgetMaterial,
  StyledExtra,
} from '@iso/assets/styles/budgetItem.styles';
import { CollapseStyle1 } from '@iso/assets/styles/collapse.styles';
import DividerStyle1 from '@iso/assets/styles/antDivider.styles';
import BudgetImage from './BudgetImage';

const keyMappings = {
  totalPrice: ['totalPrice', 'total_item'],
  proposalBudgetId: ['proposalBudgetId', 'id'],
  name: ['name'],
  specification: ['specification'],
  budgetImages: ['budgetImages', 'budget_image', 'buget_image'],
  materialType: ['materialType', 'material_type'],
  materialUnit: ['materialUnit', 'material_unit'],
  materialUnitPrice: ['materialUnitPrice', 'material_unit_price'],
  quantity: ['quantity', 'qty', 'materialQuantity'],
  manpowerRate: ['manpowerRate', 'manpower_rate'],
  days: ['days'],
  price: ['price'],
};

const mapBudgetKeys = (budget, mappings) => {
  const mappedBudget = {};
  Object.keys(mappings).forEach((key) => {
    const foundKey = mappings[key].find((possibleKey) =>
      Object.prototype.hasOwnProperty.call(budget, possibleKey),
    );
    if (foundKey) {
      mappedBudget[key] = budget[foundKey];
    }
  });
  return mappedBudget;
};

const BudgetItemV2 = ({
  budget,
  showMenu = true,
  handleEdit,
  handleDelete,
  isChangeRequest = false,
}) => {
  const { Panel } = Collapse;
  const mappedBudget = mapBudgetKeys(budget, keyMappings);
  const BudgetExtra = () => (
    <StyledExtra>
      <Space style={{ justifyContent: 'space-between', width: '100%' }}>
        {mappedBudget.hasOwnProperty('price') && (
          <Space>
            <h3 className="info-title">Amount:</h3>
            <p className="info-value" style={{ textAlign: 'end' }}>
              {formatAmount(mappedBudget?.price)}
              <span style={{ fontSize: '10px', marginLeft: '4px' }}>AED</span>
            </p>
          </Space>
        )}
        {mappedBudget.hasOwnProperty('totalPrice') && (
          <Space>
            <h3 className="info-title">Total Price:</h3>
            <p className="info-value" style={{ textAlign: 'end' }}>
              {formatAmount(mappedBudget?.totalPrice)}
              <span style={{ fontSize: '10px', marginLeft: '4px' }}>AED</span>
            </p>
          </Space>
        )}
        {showMenu && (
          <Space>
            <OptionsMenu onTriggerClick={(e) => e.stopPropagation()}>
              {(onClose) => (
                <Menu>
                  <OptionMenuButton
                    type="text"
                    onClick={(e) => handleEdit(e, onClose)}
                  >
                    <IntlMessages id="common.edit" />
                  </OptionMenuButton>
                  {handleDelete && (
                    <>
                      <DividerStyle1 />
                      <OptionMenuButton
                        type="text"
                        onClick={(e) => handleDelete(e, onClose)}
                      >
                        <IntlMessages id="common.delete" />
                      </OptionMenuButton>
                    </>
                  )}
                </Menu>
              )}
            </OptionsMenu>
          </Space>
        )}
      </Space>
    </StyledExtra>
  );
  const BudgetHeader = () => (
    <Space
      style={{
        justifyContent: 'flex-start',
        rowGap: '20px',
        columnGap: '20px',
        width: '100%',
      }}
    >
      <h2 className="ant-collapse-title">{mappedBudget.name}</h2>
    </Space>
  );

  return (
    <CollapseStyle1
      className="ant-collapse-style1"
      key={mappedBudget.proposalBudgetId}
    >
      <Panel
        header={<BudgetHeader />}
        extra={<BudgetExtra />}
        key={mappedBudget.proposalBudgetId}
        className="panel-style1"
      >
        <Space direction="vertical">
          <h3 className="info-title">Specification:</h3>
          <p className="info-value">
            {formatEmptyValue(mappedBudget.specification)}
          </p>
        </Space>
        <BudgetDetailsDivider />
        <BudgetContent>
          <div style={{ width: '128px' }}>
            <BudgetImage
              images={isChangeRequest ? budget.file : mappedBudget.budgetImages}
              budgetId={isChangeRequest ? budget.proposalBudgetId : budget.id}
              isNew={budget.isNew}
              isChangeRequest={isChangeRequest}
            />
          </div>
          <BudgetMaterial style={{ marginLeft: '50px' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <BudgetMaterial>
                <Space direction="vertical">
                  <h3 className="info-title">Material Type</h3>
                  <p className="info-value">
                    {formatEmptyValue(mappedBudget.materialType)}
                  </p>
                </Space>
                <Space direction="vertical">
                  <h3 className="info-title">Material Unit</h3>
                  <p className="info-value">
                    {formatEmptyValue(mappedBudget.materialUnit)}
                  </p>
                </Space>
                <Space direction="vertical">
                  <h3 className="info-title">Material Unit Price</h3>
                  <p className="info-value">
                    {formattedAmount(mappedBudget?.materialUnitPrice)}
                  </p>
                </Space>
                <Space direction="vertical">
                  <h3 className="info-title">Quantity</h3>
                  <p className="info-value">
                    {formatEmptyValue(mappedBudget.quantity)}
                  </p>
                </Space>
                <Space direction="vertical">
                  <h3 className="info-title">Material Unit Cost</h3>
                  <p className="info-value">
                    {formattedAmount(budget?.unit_price)}
                  </p>
                </Space>
              </BudgetMaterial>
              <BudgetMaterial>
                <Space direction="vertical">
                  <h3 className="info-title">Manpower Rate</h3>
                  <p className="info-value">
                    {formatEmptyValue(mappedBudget.manpowerRate)}
                  </p>
                </Space>
                <Space direction="vertical">
                  <h3 className="info-title">days</h3>
                  <p className="info-value">
                    {formatEmptyValue(mappedBudget.days)}
                  </p>
                </Space>
              </BudgetMaterial>
            </Space>
          </BudgetMaterial>
        </BudgetContent>
      </Panel>
    </CollapseStyle1>
  );
};

export default BudgetItemV2;
