import getApiData from '@iso/lib/helpers/apiHelper';
import { apiEndpoints } from '@iso/config/apiEndpoints';
import {
  FETCH_PROPOSAL_MILESTONES,
  ADD_NEW_MILESTONE,
  ADD_MILESTONE_BUDGET,
  FETCH_CHANGE_REQUEST,
  SUBMIT_CHANGE_REQUEST,
  FETCH_CHANGE_REQUESTS,
} from '@iso/redux/changeRequest/actions';
import { call, takeLatest } from 'redux-saga/effects';

function* fetchProposalMilestones(action) {
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.PROPOSAL_MILESTONES(action.payload.proposalId),
      undefined,
      undefined,
      undefined,
      undefined,
    );
    console.log(res);
    if (res.success) {
      action.payload.successCallback(res);
    } else {
      action.payload.errorCallback(res);
    }
  } catch (error) {
    action.payload.errorCallback(error);
  }
}

function* addNewMilestone(action) {
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.ADD_MILESTONE_CHANGE_REQUEST(),
      action.payload.data,
      'POST',
      undefined,
      undefined,
      undefined,
      'alternate',
    );
    console.log(res);
    action.payload.successCallback(res);
  } catch (error) {
    action.payload.errorCallback(error);
  }
}

function* addMilestoneBudget(action) {
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.ADD_BUDGET_CHANGE_REQUEST(action.payload.milestoneId),
      action.payload.data,
      'POST',
      undefined,
      undefined,
      undefined,
      'alternate',
    );
    console.log(res);
    action.payload.successCallback(res);
  } catch (error) {
    action.payload.errorCallback(error);
  }
}

function* fetchChangeRequestDetails(action) {
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.CHANGE_REQUEST_BY_ID(action.payload.changeRequestId),
      '',
      undefined,
      undefined,
      undefined,
      undefined,
      'alternate',
    );
    console.log(res);
    action.payload.successCallback(res);
  } catch (error) {
    action.payload.errorCallback(error);
  }
}

function* submitChangeRequest(action) {
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.SUBMIT_CHANGE_REQUEST(),
      action.payload.data,
      'POST',
      undefined,
      undefined,
      undefined,
      'alternate',
    );
    console.log(res);
    action.payload.successCallback(res);
  } catch (error) {
    action.payload.errorCallback(error);
  }
}

function* fetchChangeRequests(action) {
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.CHANGE_REQUESTS(action.payload.projectId),
      { role: 'admin' },
      undefined,
      undefined,
      undefined,
      undefined,
      'alternate',
    );
    console.log(res);
    action.payload.successCallback(res);
  } catch (error) {
    action.payload.errorCallback(error);
  }
}

export default function* watchFetchData() {
  yield takeLatest(FETCH_PROPOSAL_MILESTONES, fetchProposalMilestones);
  yield takeLatest(ADD_NEW_MILESTONE, addNewMilestone);
  yield takeLatest(ADD_MILESTONE_BUDGET, addMilestoneBudget);
  yield takeLatest(FETCH_CHANGE_REQUEST, fetchChangeRequestDetails);
  yield takeLatest(SUBMIT_CHANGE_REQUEST, submitChangeRequest);
  yield takeLatest(FETCH_CHANGE_REQUESTS, fetchChangeRequests);
}
