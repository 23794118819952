const getHasDetails = (proposal) => {
  if (!proposal) return false;
  return Boolean(proposal.details);
};

const getHasMilestones = (proposal) => {
  if (!proposal) return false;
  return proposal.milestoneIds.length > 0;
};

const getHasBudgets = (proposal) => {
  if (!proposal) return false;
  const doesAnyMilestoneHaveBudget = proposal.milestoneIds.some(
    (milestoneId) => {
      const milestone = proposal.milestones[milestoneId];
      if (milestone?.budgetIds?.length > 0) return true;
      return false;
    },
  );
  return doesAnyMilestoneHaveBudget;
};

const getHasPaymentGroups = (proposal) => {
  if (!proposal) return false;
  return proposal.paymentGroupIds.length > 0;
};

// eslint-disable-next-line no-unused-vars
export const getIsDetailsTabEnabled = (proposal) => true;

// eslint-disable-next-line no-unused-vars
const getDetails = (proposal) => ({
  title: 'details.title',
  type: 'details',
  isEnabled: true,
});

export const getIsMilestonesTabEnabled = (proposal) => {
  return getDetails(proposal).isEnabled && getHasDetails(proposal);
};

const getMilestones = (proposal) => ({
  title: 'milestones.title',
  type: 'milestones',
  isEnabled: getIsMilestonesTabEnabled(proposal),
});

export const getIsBudgetsTabEnabled = (proposal) => {
  return getMilestones(proposal).isEnabled && getHasMilestones(proposal);
};

const getBudgets = (proposal) => ({
  title: 'budgets.title',
  type: 'budgets',
  isEnabled: getIsBudgetsTabEnabled(proposal),
});

export const getIsPaymentGroupsTabEnabled = (proposal) => {
  return getBudgets(proposal).isEnabled && getHasBudgets(proposal);
};

const getPaymentGroups = (proposal) => ({
  title: 'payment.groups.title',
  type: 'paymentGroups',
  isEnabled: getIsPaymentGroupsTabEnabled(proposal),
});

export const getIsSummaryTabEnabled = (proposal) => {
  return getPaymentGroups(proposal).isEnabled && getHasPaymentGroups(proposal);
};

const getSummary = (proposal) => ({
  title: 'summary.title',
  type: 'summary',
  isEnabled: getIsSummaryTabEnabled(proposal),
});

export const getTabs = (proposal) => [
  {
    ...getDetails(proposal),
    id: 1,
  },
  {
    ...getMilestones(proposal),
    id: 2,
  },
  {
    ...getBudgets(proposal),
    id: 3,
  },
  {
    ...getPaymentGroups(proposal),
    id: 4,
  },
  {
    ...getSummary(proposal),
    id: 5,
  },
];

export const getDraftTabValue = (tabValue) => {
  switch (tabValue) {
    case 'first_tab':
      return 1;
    case 'second_tab':
      return 2;
    case 'third_tab':
      return 3;
    case 'fourth_tab':
      return 4;
    case 'fifth_tab':
      return 5;
    default:
      return 1;
  }
};
