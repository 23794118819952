import styled from 'styled-components';

export const StyledDiv = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  //gap: 16px;
  row-gap: 16px;
  column-gap: 16px;
`;

export const StyledExtra = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr minmax(1fr, 1fr);
  //gap: 16px;
  row-gap: 16px;
  column-gap: 16px;
  min-width: 40%;
`;

export const BudgetMaterial = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 10%;
  width: 100%;
`;
