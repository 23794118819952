import React, { useEffect, useMemo } from 'react';
import { changeRequestTabsIds } from '@iso/lib/constants/changeRequestTabs';
import MilestoneForm from '@iso/components/Proposal/MilestoneForm';
import { Checkbox, Col, Form, Row, Select } from 'antd';
import {
  addNewMilestone,
  setActiveTabKey,
  setChangeRequest,
} from '@iso/redux/changeRequest/actions';
import { useDispatch, useSelector } from 'react-redux';
import { removeEmptyKeys } from '@iso/lib/helpers/utility';
import { AntFormStyles } from '@iso/assets/styles/antForm.styles';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Notification from '@iso/components/Notification';
import { milestoneTypes } from '@iso/lib/constants/milestoneTypes';
import { useWatch } from 'antd/lib/form/Form';

export default function AddMilestone({ TabFooter }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const proposalId = searchParams.get('proposalId');
  const projectEndDate = searchParams.get('projectEndDate');
  const { changeRequest, milestones } = useSelector(
    (state) => state.ChangeRequest,
  );
  const [isReplaceExistingMilestone, setIsReplaceExistingMilestone] =
    React.useState(!!changeRequest.oldMilestone);

  const milestoneInitialValues = {
    name: changeRequest.newMilestone?.milestoneName ?? '',
    milestoneType: changeRequest.newMilestone?.tags || milestoneTypes.design,
    description: changeRequest.newMilestone?.description ?? '',
    startDate: changeRequest.newMilestone?.startDate
      ? moment(changeRequest.newMilestone?.startDate)
      : '',
    endDate: changeRequest.newMilestone?.endDate
      ? moment(changeRequest.newMilestone?.endDate)
      : '',
    changeRequestPrice: changeRequest.details?.changeRequestPrice ?? '',
    oldMilestoneId: changeRequest?.oldMilestone?.milestoneId ?? null,
  };
  useEffect(() => {
    form.setFieldsValue(milestoneInitialValues);
    setIsReplaceExistingMilestone(!!changeRequest.oldMilestone);
    return () => {
      form.resetFields();
    };
  }, [changeRequest, form]);

  function handleSubmit(values) {
    setIsLoading(true);

    const isStartEndDateValid = moment(values.endDate).isSameOrAfter(
      values.startDate,
    );

    if (!isStartEndDateValid) {
      Notification('error', 'End Date should be after Start Date');
      setIsLoading(false);
    }

    const payload = {
      changeRequestId: changeRequest?.details?.changeRequestId, // this parameter is required only if you want to edit the change request
      milestoneId: changeRequest?.newMilestone?.milestoneId, // this parameter is required only if you want to edit the change request
      proposalId,
      milestoneName: values.name,
      description: values.description,
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD'),
      action: 'save',
      oldMilestoneId: values.oldMilestoneId, // Optinal? I should send it in case of a milesotne replacement
      changeRequestPrice: values.changeRequestPrice,
      tags: values.milestoneType,
    };
    dispatch(
      addNewMilestone({
        data: removeEmptyKeys(payload),
        successCallback: (response) => {
          const newChangeRequestId = response.changeRequest.changeRequestId;
          dispatch(
            setChangeRequest({
              details: response.changeRequest,
              newMilestone: response.proposalMilestone,
            }),
          );
          setIsLoading(false);
          history.replace({
            pathname: `/dashboard/project-management/?changeRequestId=${newChangeRequestId.toString()}&proposalId=${proposalId}&projectEndDate=${projectEndDate}`,
          });

          dispatch(setActiveTabKey(changeRequestTabsIds['2']));
          Notification('success', 'Milestone added successfully');
        },
        errorCallback: (error) => {
          setIsLoading(false);
          Notification('error', error.body.message);
        },
      }),
    );
  }

  const oldMilestoneId = useWatch('oldMilestoneId', form);
  const selectedOldMilestone = useMemo(
    () =>
      (milestones || []).filter((item) => item.id === oldMilestoneId)[0] || {},
    [oldMilestoneId, milestones],
  );

  return (
    <AntFormStyles>
      <MilestoneForm
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        isDate
        colSpan={12}
        initialValues={{ ...milestoneInitialValues }}
        prefixFields={
          <Row gutter={24}>
            <Col span="24">
              <Form.Item>
                <Checkbox
                  checked={isReplaceExistingMilestone}
                  onChange={(e) =>
                    setIsReplaceExistingMilestone(e.target.checked)
                  }
                >
                  I want to replace an existing milestone.
                </Checkbox>
              </Form.Item>
            </Col>
            {isReplaceExistingMilestone && (
              <Col span="12">
                <Form.Item
                  label="Old milestone"
                  name="oldMilestoneId"
                  rules={[
                    {
                      required: isReplaceExistingMilestone,
                      message: 'Please choose an existing milestone',
                    },
                  ]}
                >
                  <Select placeholder="Choose a milestone..." allowClear>
                    {milestones
                      .filter((item) => item.status === 'pending')
                      .map((milestone) => (
                        <Select.Option key={milestone.id} value={milestone.id}>
                          {milestone.milestone_name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                {/* <div>{selectedOldMilestone?.amount}</div> */}
              </Col>
            )}
          </Row>
        }
      />
      <TabFooter handleSaveButton={() => form.submit()} loading={isLoading} />
    </AntFormStyles>
  );
}
