import { v4 as uuid } from 'uuid';

import {
  SAVE_DETAILS,
  CREATE_MILESTONE,
  DELETE_MILESTONE,
  UPDATE_MILESTONE,
  CREATE_BUDGET,
  DELETE_BUDGET,
  UPDATE_BUDGET,
  CREATE_PAYMENT_GROUP,
  DELETE_PAYMENT_GROUP,
  UPDATE_PAYMENT_GROUP,
  SAVE_DRAFT_PROPOSAL_LOADING,
  SAVE_DRAFT_PROPOSAL_SUCCESS,
  SAVE_DRAFT_PROPOSAL_FAILURE,
  INCREMENT_ACTIVE_TAB_ID,
  DECREMENT_ACTIVE_TAB_ID,
  CHANGE_ACTIVE_TAB_ID,
  RESET_PROPOSAL,
  SET_INITIAL_STATE,
  DELETE_PROPOSAL_IMAGE_SUCCESS,
  GET_CONTRACTORS_LIST_SUCCESS,
  ASSIGN_CONTRACTOR_LOADING,
  ASSIGN_CONTRACTOR_SUCCESS,
  ASSIGN_CONTRACTOR_FAILURE,
  GET_CONTRACTORS_LIST_LOADING,
  RESET_ASSIGN_CONTRACTOR_SUCCESS_MESSAGE,
  RESET_CONTRACTORS_LIST,
  GET_PROJECT_TYPES_LOADING,
  GET_PROJECT_TYPES_SUCCESS,
  GET_PROJECT_TYPES_FAILURE,
  SET_CALCULATION_STATUS,
} from './actions';

const MIN_TAB_ID = 1;
const MAX_TAB_ID = 5;

const initialState = {
  // number between 1 and 5 both inclusive
  activeTabId: 1,

  // string or null
  projectId: null,

  // string or null
  proposalId: null,

  /**
   *  {
   * 		type: 'Interior Design' | 'Kitchen' | 'Home Renovation' | 'Bathroom' | 'Landscaping' | 'Rooms';
   * 		projectName: string;
   * 		description: string;
   * 		customerName: string;
   * 		customerEmail: string;
   * 		scopeOfWork: string;
   * 		projectFiles: File[];
   *  }
   */
  details: null,

  /**
   *  {
   * 		[milestoneId]: {
   * 		    name: string;
   * 		    description: string;
   * 		    budgetIds: string[];
   * 		    paymentGroupId: string | null;
   * 		},
   *    ....
   *  }
   */
  milestones: {},

  /** string[] */
  milestoneIds: [],

  /**
   *  {
   *    [budgetId]: {
   *        name: string;
   *        description: string;
   *        materialType: string;
   *        materialUnit: '' | 'tonns' | 'Kg' | 'g' | 'lbs' | 'litre' | 'ml' | 'sqm' | 'item';
   *        materialQuantity: number;
   *        milestoneId: string;
   *        budgetImages: []; Upload.Dragger returned array
   *    },
   *    ...
   *  }
   */
  budgets: {},

  /**
   *  {
   *    [paymentGroupId]: {
   *        name: string;
   *        milestoneIds: string[];
   *    },
   *    ...
   *  },
   */
  paymentGroups: {},

  /** string[] */
  paymentGroupIds: [],

  // boolean
  isSavingDraftProposal: false,

  // string or null
  saveProposalErrorMessage: null,

  // Array of project types
  projectTypes: [],

  // boolean
  isFetchingProjectTypes: false,

  // string or null
  projectTypesErrorMessage: null,

  // boolean
  isDone: false,
  // Array of contractors
  contractorsList: null,
  // boolean
  isFetchingContractors: false,
  // boolean
  isAssignedContractors: false,
  // string or null
  assignedContractorsSuccessMessage: null,
  // string or null
  assignedContractorsErrorMessage: null,
  calculationStatus: {
    calculationDone: false,
    loading: false,
  },
};

function createProposalReducer(state = initialState, action) {
  switch (action.type) {
    case INCREMENT_ACTIVE_TAB_ID: {
      const nextId = state.activeTabId + 1;
      return {
        ...state,
        activeTabId: nextId > MAX_TAB_ID ? MAX_TAB_ID : nextId,
      };
    }
    case DECREMENT_ACTIVE_TAB_ID: {
      const prevId = state.activeTabId - 1;
      return {
        ...state,
        activeTabId: prevId < MIN_TAB_ID ? MIN_TAB_ID : prevId,
      };
    }
    case CHANGE_ACTIVE_TAB_ID:
      return {
        ...state,
        activeTabId:
          action.payload < MIN_TAB_ID || action.payload > MAX_TAB_ID
            ? state.activeTabId
            : action.payload,
      };
    case SAVE_DETAILS:
      return { ...state, details: action.payload };
    case CREATE_MILESTONE: {
      const newMilestoneId = uuid();
      return {
        ...state,
        milestones: {
          ...state.milestones,
          [newMilestoneId]: {
            ...action.payload,
            id: newMilestoneId,
            budgetIds: [],
            paymentGroupId: null,
            isNew: true,
          },
        },
        milestoneIds: [...state.milestoneIds, newMilestoneId],
      };
    }
    case DELETE_MILESTONE: {
      const milestones = { ...state.milestones };
      const { budgetIds, paymentGroupId } = milestones[action.payload];

      // delete milestone
      delete milestones[action.payload];

      // delete corresponding budgets
      const budgets = { ...state.budgets };
      budgetIds.forEach((budgetId) => delete budgets[budgetId]);

      // delete milestone of payment group or delete corresponding payment group
      const paymentGroups = { ...state.paymentGroups };
      let paymentGroupIds = [...state.paymentGroupIds];

      if (paymentGroupId) {
        // delete milestone from payment group
        paymentGroups[paymentGroupId].milestoneIds = paymentGroups[
          paymentGroupId
        ].milestoneIds.filter((milestoneId) => milestoneId !== action.payload);

        // delete payment group when there are no milestones
        if (paymentGroups[paymentGroupId].milestoneIds.length === 0) {
          delete paymentGroups[paymentGroupId];
          paymentGroupIds = paymentGroupIds.filter(
            (_paymentGroupId) => _paymentGroupId !== paymentGroupId,
          );
        }
      }

      return {
        ...state,
        milestones,
        milestoneIds: state.milestoneIds.filter(
          (milestoneId) => milestoneId !== action.payload,
        ),
        budgets,
        paymentGroups,
        paymentGroupIds,
      };
    }
    case UPDATE_MILESTONE:
      return {
        ...state,
        milestones: {
          ...state.milestones,
          [action.payload.id]: {
            ...state.milestones[action.payload.id],
            ...action.payload,
          },
        },
      };
    case CREATE_BUDGET: {
      const newBudgetId = uuid();
      const { milestoneId } = action.payload;
      return {
        ...state,
        budgets: {
          ...state.budgets,
          [newBudgetId]: {
            ...action.payload,
            id: newBudgetId,
            isNew: true,
          },
        },
        milestones: {
          ...state.milestones,
          [milestoneId]: {
            ...state.milestones[milestoneId],
            budgetIds: [
              ...state.milestones[milestoneId].budgetIds,
              newBudgetId,
            ],
          },
        },
      };
    }
    case DELETE_BUDGET: {
      const budgets = { ...state.budgets };
      const { milestoneId } = budgets[action.payload];

      // delete budget
      delete budgets[action.payload];

      return {
        ...state,
        budgets,
        milestones: {
          ...state.milestones,
          // remove budget id from milestone
          [milestoneId]: {
            ...state.milestones[milestoneId],
            budgetIds: state.milestones[milestoneId].budgetIds.filter(
              (budgetId) => budgetId !== action.payload,
            ),
          },
        },
      };
    }
    case UPDATE_BUDGET:
      return {
        ...state,
        budgets: {
          ...state.budgets,
          [action.payload.id]: action.payload,
        },
      };
    case CREATE_PAYMENT_GROUP: {
      const newPaymentGroupId = uuid();

      const milestones = {};
      Object.keys(state.milestones).forEach((milestoneId) => {
        const milestone = state.milestones[milestoneId];
        const isMilestonePartOfNewPaymentGroup = action.payload.milestoneIds
          .map((x) => x.toString())
          .includes(milestoneId.toString());
        milestones[milestoneId] = isMilestonePartOfNewPaymentGroup
          ? { ...milestone, paymentGroupId: newPaymentGroupId }
          : milestone;
      });

      return {
        ...state,
        paymentGroups: {
          ...state.paymentGroups,
          [newPaymentGroupId]: {
            ...action.payload,
            id: newPaymentGroupId,
          },
        },
        paymentGroupIds: [...state.paymentGroupIds, newPaymentGroupId],
        milestones,
      };
    }
    case DELETE_PAYMENT_GROUP: {
      const paymentGroups = { ...state.paymentGroups };
      const { milestoneIds } = paymentGroups[action.payload];

      // delete payment group
      delete paymentGroups[action.payload];

      // remove payment group id from associated milestones
      const milestones = {};
      Object.keys(state.milestones).forEach((milestoneId) => {
        const milestone = state.milestones[milestoneId];
        const isMilestonePartOfPaymentGroup = milestoneIds.includes(
          milestone.id,
        );
        milestones[milestoneId] = isMilestonePartOfPaymentGroup
          ? { ...milestone, paymentGroupId: null }
          : milestone;
      });

      return {
        ...state,
        paymentGroups,
        paymentGroupIds: state.paymentGroupIds.filter(
          (paymentGroupId) => paymentGroupId !== action.payload,
        ),
        milestones,
      };
    }
    case UPDATE_PAYMENT_GROUP: {
      const milestones = {};
      Object.keys(state.milestones).forEach((milestoneId) => {
        const milestone = state.milestones[milestoneId];
        const paymentGroupToUpdate = state.paymentGroups[action.payload.id];
        const isMilestonePartOfPaymentGroupBeforeUpdate =
          paymentGroupToUpdate.milestoneIds.includes(milestoneId);
        const x = isMilestonePartOfPaymentGroupBeforeUpdate
          ? { ...milestone, paymentGroupId: null }
          : milestone;
        milestones[milestoneId] = x;
      });
      Object.keys(milestones).forEach((milestoneId) => {
        const milestone = milestones[milestoneId];
        const updatedPaymentGroup = action.payload;
        const isMilestonePartOfPaymentGroupAfterUpdate =
          updatedPaymentGroup.milestoneIds.includes(milestoneId);
        milestones[milestoneId] = isMilestonePartOfPaymentGroupAfterUpdate
          ? { ...milestone, paymentGroupId: updatedPaymentGroup.id }
          : milestone;
      });

      return {
        ...state,
        milestones,
        paymentGroups: {
          ...state.paymentGroups,
          [action.payload.id]: action.payload,
        },
      };
    }
    case SAVE_DRAFT_PROPOSAL_LOADING:
      return {
        ...state,
        isSavingDraftProposal: true,
        saveProposalErrorMessage: null,
      };
    case SAVE_DRAFT_PROPOSAL_SUCCESS: {
      const { budgetImages } = action.payload;

      const nextTabId = state.activeTabId + 1;

      const budgets = { ...state.budgets };
      let budgetCount = 0;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < state.milestoneIds.length; i++) {
        const milestoneId = state.milestoneIds[i];
        const milestone = state.milestones[milestoneId];
        // eslint-disable-next-line no-continue
        if (!milestone) continue;

        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < milestone.budgetIds.length; j++) {
          const budgetId = milestone.budgetIds[j];
          const budget = state.budgets[budgetId];
          budgetCount += 1;
          // eslint-disable-next-line no-continue
          if (!budget) continue;

          budgets[budgetId] = {
            ...budget,
            budgetImages: budget.budgetImages.map(
              // eslint-disable-next-line no-loop-func
              (budgetImage, budgetImageIndex) => {
                const image = {
                  ...budgetImage,
                  // isAlreadyUploaded: true,
                };

                if (budgetImages && Object.keys(budgetImages).length > 0) {
                  const imageId =
                    budgetImages[`budget_image_${budgetCount}`][
                    budgetImageIndex
                    ];
                  if (imageId) {
                    image.id = imageId;
                  }
                }

                return image;
              },
            ),
          };
        }
      }

      return {
        ...state,
        isSavingDraftProposal: false,
        ...action.payload,
        isDone: action.payload.isDone ?? false,
        activeTabId: nextTabId > MAX_TAB_ID ? MAX_TAB_ID : nextTabId,
        details: {
          ...state.details,
          projectFiles: action.payload.projectFiles.map((file) => {
            return {
              ...file,
              isAlreadyUploaded: true,
            };
          }),
        },
        budgets,
      };
    }
    case SAVE_DRAFT_PROPOSAL_FAILURE:
      return {
        ...state,
        isSavingDraftProposal: false,
        saveProposalErrorMessage: action.payload,
      };
    case RESET_PROPOSAL:
      return {
        ...initialState,
      };

    case DELETE_PROPOSAL_IMAGE_SUCCESS:
      return {
        ...state,
        projectFiles: state.details.projectFiles.filter(
          (file) => file.id !== action.payload,
        ),
        budgets: Object.keys(state.budgets).reduce((acc, budgetKey) => {
          const budget = state.budgets[budgetKey];
          acc[budgetKey] = {
            ...budget,
            budgetImages: budget.budgetImages.filter(
              (file) => file.id !== action.payload,
            ),
          };
          return acc;
        }, {}),
      };
    case SET_INITIAL_STATE:
      return {
        ...initialState,
        ...action.payload,
      };

    case GET_CONTRACTORS_LIST_LOADING:
      return {
        ...state,
        isFetchingContractors: true,
      };
    case GET_CONTRACTORS_LIST_SUCCESS:
      return {
        ...state,
        isFetchingContractors: false,
        contractorsList: action.payload.data,
        projectId: action.payload.projectId,
      };
    case ASSIGN_CONTRACTOR_LOADING:
      return {
        ...state,
        isAssignedContractors: true,
        assignedContractorsErrorMessage: null,
      };
    case ASSIGN_CONTRACTOR_SUCCESS:
      return {
        ...state,
        isAssignedContractors: false,
        assignedContractorsSuccessMessage: action.payload,
      };
    case ASSIGN_CONTRACTOR_FAILURE:
      return {
        ...state,
        isAssignedContractors: false,
        assignedContractorsErrorMessage: action.payload,
      };
    case RESET_ASSIGN_CONTRACTOR_SUCCESS_MESSAGE:
      return {
        ...state,
        assignedContractorsSuccessMessage: null,
      };
    case RESET_CONTRACTORS_LIST:
      return {
        ...state,
        contractorsList: null,
      };
    case GET_PROJECT_TYPES_LOADING:
      return {
        ...state,
        isFetchingProjectTypes: true,
      };
    case GET_PROJECT_TYPES_SUCCESS:
      return {
        ...state,
        isFetchingProjectTypes: false,
        projectTypes: action.payload,
      };
    case GET_PROJECT_TYPES_FAILURE:
      return {
        ...state,
        isFetchingProjectTypes: false,
        projectTypesErrorMessage: action.payload,
      };
    case SET_CALCULATION_STATUS:
      return {
        ...state,
        calculationStatus: action.payload,
      };
    default:
      return { ...state };
  }
}

export default createProposalReducer;
