export default {
  siteName: 'Reno',
  siteIcon: 'ion-flash',
  footerText: `Copyright © ${new Date().getFullYear()}. All rights reserved by Reno`,
  wentWrongTxt: 'Something went wrong. Try later',
  enableAnimatedRoute: false,

  // apiUrl: 'http://192.168.0.107:1337/api/',
  apiUrl: process.env.REACT_APP_BASE_URL ?? 'https://reno-dev.azurewebsites.net/',
  alternateApiUrl: process.env.REACT_APP_NEW_BASE_URL ?? 'https://reno-core-api-test.azurewebsites.net/',
  siteUrl: process.env.REACT_APP_BASE_URL ?? 'https://reno-test.azurewebsites.net/',
  csvUrl: 'https://localhost:1337/SampleCsv',
  domainUrl: process.env.REACT_APP_DOMAIN_URL ?? 'http://localhost:3000',
  sailsUrl: process.env.REACT_APP_DOMAIN_URL,

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  tinyEditorKey: 'fmgz7dzes6qjb1apg4645ac54vumgn4xkksqmsxd9xk819sv',
  dashboard: '/dashboard',
  stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY,
  googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  postcodeApiKey: process.env.REACT_APP_POSTCODE_API_KEY,
  paypalClientId: '',
};
