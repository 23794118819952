import React, { useEffect } from 'react';
import { Button, Col, Form, Modal, Row, Space } from 'antd';
import { AntFormStyles } from '@iso/assets/styles/antForm.styles';
import BudgetForm from '@iso/components/Proposal/BudgetForm';

const UpsertBudgetDialog = ({
  isOpen,
  loading,
  budget,
  handleClose,
  handleSubmit,
}) => {
  const [form] = Form.useForm();

  const budgetInitialValues = {
    name: budget?.name ?? '',
    materialType: budget?.materialType ?? '',
    materialUnit: budget?.materialUnit ?? null,
    quantity: budget?.quantity ?? '',
    materialUnitPrice: budget?.materialUnitPrice ?? '',
    manpowerRate: budget?.manpowerRate ?? '',
    days: budget?.days ?? '',
    specification: budget?.specification ?? '',
  };
  useEffect(() => {
    form.setFieldsValue(budgetInitialValues);
    return () => {
      form.resetFields();
    };
  }, [budget, form, isOpen]);

  return (
    <Modal
      open={isOpen}
      onCancel={handleClose}
      width="75%"
      title={budget ? 'Edit budget' : 'Add new Budget'}
      className="modal-no-footer"
      zIndex={1002}
    >
      <AntFormStyles>
        <BudgetForm
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          initialValues={{ ...budgetInitialValues }}
        />
      </AntFormStyles>
      <Row justify="center">
        <Col>
          <Button
            type="primary"
            loading={loading}
            disabled={loading}
            onClick={(budgetId) => {
              form.submit();
            }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default UpsertBudgetDialog;
