import { Checkbox, Col, DatePicker, Form, Input, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { StyledSpace } from '@iso/assets/styles/antSpace.styles';

export default function SubmitForm({ ...restProps }) {
  const { form } = restProps;
  const { Text } = Typography;
  const { changeRequest } = useSelector((state) => state.ChangeRequest);
  const [isNewMoveInDate, setIsNewMoveInDate] = useState();
  const [pricePercentage, setPricePercentage] = useState({
    data: 0,
    flag: 'secondary',
  });
  const searchParams = new URLSearchParams(window.location.search);
  const projectEndDate = searchParams.get('projectEndDate');
  const disabledBeforeStartDate = (current) => {
    // Can not select days before today
    return current && current < moment(projectEndDate);
  };

  const handlePriceChange = () => {
    const changeRequestPrice = form.getFieldValue('changeRequestPrice');
    const changeRequestCost = changeRequest?.details?.changeRequestCost;
    if (changeRequestCost && changeRequestPrice) {
      const percentage = Number(
        (
          ((changeRequestPrice - changeRequestCost) / changeRequestCost) *
          100
        ).toFixed(2),
      );
      const flag =
        percentage < 0 ? 'danger' : percentage === 0 ? 'warning' : 'success';
      setPricePercentage({ data: percentage, flag });
    } else {
      setPricePercentage('');
    }
  };

  useEffect(() => {
    form.setFieldValue(
      'newMoveInDate',
      changeRequest.details?.newMoveInDate
        ? moment(changeRequest.details?.newMoveInDate, 'YYYY-MM-DD')
        : null,
    );
    form.setFieldValue(
      'changeRequestPrice',
      changeRequest.details?.changeRequestPrice
        ? changeRequest.details?.changeRequestPrice
        : null,
    );
    setIsNewMoveInDate(!!changeRequest.details?.newMoveInDate);
    handlePriceChange();
  }, [
    changeRequest.details?.newMoveInDate,
    changeRequest.details?.changeRequestPrice,
  ]);

  useEffect(() => {
    if (!isNewMoveInDate) {
      form.setFieldValue('newMoveInDate', '');
    }
  }, [isNewMoveInDate]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Form {...restProps}>
      <Row gutter={24} style={{ marginBottom: '30px' }}>
        <Col span={15}>
          <StyledSpace
            size="large"
            direction="horizontal"
            style={{ width: '100%', height: '40px' }}
          >
            <Form.Item>
              <Checkbox
                checked={isNewMoveInDate}
                onChange={(e) => setIsNewMoveInDate(e.target.checked)}
              >
                I want to add a new move-in date
              </Checkbox>
            </Form.Item>
            {isNewMoveInDate && (
              <Form.Item
                label="New Move-In Date"
                name="newMoveInDate"
                style={{ marginBottom: 0 }}
              >
                <DatePicker
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Add new move-in date"
                  format="MMM DD, YYYY"
                  disabledDate={disabledBeforeStartDate}
                />
              </Form.Item>
            )}
          </StyledSpace>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={15}>
          <StyledSpace
            size="large"
            direction="horizontal"
            style={{ width: '100%' }}
          >
            <Form.Item label="Price" name="changeRequestPrice">
              <Input
                type="number"
                placeholder="Enter price..."
                onChange={handlePriceChange}
                allowClear
              />
            </Form.Item>
            <Form.Item label="Reno Commission">
              <Text type={pricePercentage.flag}>{pricePercentage.data} %</Text>
            </Form.Item>
          </StyledSpace>
        </Col>
      </Row>
    </Form>
  );
}
