import React, { useState } from 'react';
import { Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

import { Trigger } from './style';

const OptionsMenu = ({ onTriggerClick, children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpenChange = (visible) => {
    setIsMenuOpen(visible);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Popover
      placement="bottom"
      content={children(handleCloseMenu)}
      trigger="click"
      open={isMenuOpen}
      onOpenChange={handleMenuOpenChange}
      overlayClassName="reno-popover"
    >
      <Trigger type="text" onClick={onTriggerClick}>
        <MoreOutlined />
      </Trigger>
    </Popover>
  );
};

export default OptionsMenu;
