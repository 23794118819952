import React from 'react';
import { changeRequestTabsIds } from '@iso/lib/constants/changeRequestTabs';
import {
  addMilestoneBudget,
  setActiveTabKey,
  setChangeRequest,
} from '@iso/redux/changeRequest/actions';
import { removeEmptyKeys } from '@iso/lib/helpers/utility';
import { useDispatch, useSelector } from 'react-redux';
import { AddBudgetButton } from '@iso/containers/CreateProposal/Budgets/styles';
import { PlusOutlined } from '@ant-design/icons';
import IntlMessages from '@iso/components/utility/intlMessages';
import UpsertBudgetDialog from '@iso/containers/ChangeRequest/_components/UpsertBudgetDialog';
import BudgetItemV2 from '@iso/components/Proposal/BudgetItemV2';
import Notification from '@iso/components/Notification';

export default function AddBudget({ TabFooter }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [budgetDialog, setBudgetDialog] = React.useState({
    isOpen: false,
    data: undefined,
  });
  const { changeRequest } = useSelector((state) => state.ChangeRequest);

  function toggleBudgetDialog(budget = undefined, open) {
    setBudgetDialog({
      data: budget,
      isOpen: open,
    });
  }

  function handleSubmit(values) {
    setIsLoading(true);
    const payload = {
      proposalBudgetId: budgetDialog?.data?.proposalBudgetId, // this parameter is required only if you want to edit the budget
      changeRequestId: changeRequest?.details?.changeRequestId,
      name: values.name,
      materialType: values.materialType,
      materialUnit: values.materialUnit,
      materialUnitPrice: values.materialUnitPrice,
      quantity: values.quantity,
      manpowerRate: values.manpowerRate,
      days: values.days,
      specification: values.specification,
    };
    dispatch(
      setChangeRequest({
        ...changeRequest,
        isToFetchChangeRequest: false,
        // newMilestoneBudgets: {
        //   ...changeRequest.newMilestoneBudgets,
        //   [response.proposalBudgetId]: {
        //     ...response,
        //   },
        // },
      }),
    );
    dispatch(
      addMilestoneBudget({
        milestoneId: changeRequest.newMilestone.milestoneId,
        data: removeEmptyKeys(payload),
        successCallback: (response) => {
          setIsLoading(false);
          dispatch(
            setChangeRequest({
              ...changeRequest,
              isToFetchChangeRequest: true,
              // newMilestoneBudgets: {
              //   ...changeRequest.newMilestoneBudgets,
              //   [response.proposalBudgetId]: {
              //     ...response,
              //   },
              // },
            }),
          );
          toggleBudgetDialog(undefined, false);
          Notification('success', 'Budget added successfully');
        },
        errorCallback: (error) => {
          setIsLoading(false);
          Notification('error', error.body.message);
        },
      }),
    );
  }

  return (
    <>
      {/* loop from the budgets array here */}
      {Object.values(changeRequest.newMilestoneBudgets).map((budget) => (
        <BudgetItemV2
          key={budget.proposalBudgetId}
          budget={budget}
          budgetId={budget.proposalBudgetId}
          handleEdit={(e, onClose) => {
            e.stopPropagation();
            toggleBudgetDialog(budget, true);
            onClose();
          }}
        />
      ))}
      <AddBudgetButton
        type="primary"
        className="button-style1"
        style={{ marginTop: '20px' }}
        onClick={() => toggleBudgetDialog(undefined, true)}
      >
        <PlusOutlined />
        <IntlMessages id="add.budget" />
      </AddBudgetButton>
      <UpsertBudgetDialog
        isOpen={budgetDialog.isOpen}
        budget={budgetDialog.data}
        loading={isLoading}
        handleClose={() => toggleBudgetDialog(undefined, false)}
        handleSubmit={handleSubmit}
      />
      <TabFooter
        handleSaveButton={() => {
          if (!Object.values(changeRequest.newMilestoneBudgets).length) return;
          dispatch(setActiveTabKey(changeRequestTabsIds['3']));
        }}
        previousTab={changeRequestTabsIds['1']}
      />
    </>
  );
}
