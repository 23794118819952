const milestoneTypes = {
  design: 'Design',
  renovation: 'Renovation',
};

const milestoneTypesOptions = {
  [milestoneTypes.design]: {
    title: 'Design',
    value: milestoneTypes.design,
  },
  [milestoneTypes.renovation]: {
    title: 'Renovation',
    value: milestoneTypes.renovation,
  },
};

export { milestoneTypes, milestoneTypesOptions };
