import { inspectionsStates } from '@iso/lib/constants/inspectionsStates';
import {
  SET_FILTERS,
  SET_PAGINATION,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  SET_DATA_LOADING,
  SET_SUBMIT_LOADING,
  SET_INSPECTION_DRAWER_LOADING,
  SET_DETAILS_DRAWER_LOADING,
  RESET_PAGINATION,
  TOGGLE_INSPECTION_UPLOAD_DRAWER,
  TOGGLE_INSPECTION_DETAILS_DRAWER,
  SET_INSPECTION_UNDER_SUBMISSION,
  RESET_STATE,
} from './actions';

const paginationInitialState = { total: 0, pageSize: 20, pageNumber: 0 };
const filtersInitialState = {
  filter: inspectionsStates.due,
  project_id: null,
};
const initialState = {
  filters: filtersInitialState,
  pagination: paginationInitialState,
  data: [],
  dataLoading: false,
  inspectionDrawerLoading: false,
  detailsDrawerLoading: false,
  submitLoading: false,
  error: null,
  isInspectionUploadDrawerVisible: false,
  isInspectionDetailsDrawerVisible: false,
  inspectionUnderSubmission: null,
};

function inspectionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: { ...state.pagination, ...action.payload },
      };
    case RESET_PAGINATION:
      return {
        ...state,
        pagination: { ...state.pagination, ...paginationInitialState },
      };
    case SET_DATA_LOADING:
      return {
        ...state,
        dataLoading: action.payload,
      };
    case SET_INSPECTION_DRAWER_LOADING:
      return {
        ...state,
        inspectionDrawerLoading: action.payload,
      };
    case SET_DETAILS_DRAWER_LOADING:
      return {
        ...state,
        detailsDrawerLoading: action.payload,
      };
    case SET_SUBMIT_LOADING:
      return {
        ...state,
        submitLoading: action.payload,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload.content,
        pagination: {
          ...action.payload.pageable,
          total: action.payload.totalElements,
        },
        dataLoading: false,
      };
    case FETCH_DATA_FAILURE:
      return { ...state, error: action.payload, dataLoading: false };
    case TOGGLE_INSPECTION_UPLOAD_DRAWER:
      return { ...state, isInspectionUploadDrawerVisible: action.payload };
    case TOGGLE_INSPECTION_DETAILS_DRAWER:
      return { ...state, isInspectionDetailsDrawerVisible: action.payload };
    case SET_INSPECTION_UNDER_SUBMISSION:
      return { ...state, inspectionUnderSubmission: { ...action.payload } };
    case RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

export default inspectionReducer;
