import React, { useEffect, useState } from 'react';
import Notification from '@iso/components/Notification';
import getApiData from '@iso/lib/helpers/apiHelper';
import { Button, Upload } from 'antd';
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';

const CRImageUploader = ({
  refId,
  isOld,
  onUpload,
  isMilestone,
  initialImage,
}) => {
  const [processing, setProcessing] = useState();
  const [image, setImage] = useState();
  const [imageId, setImageId] = useState();
  const [removing, setRemoving] = useState();

  const fetchImage = async () => {
    try {
      setProcessing(false);
      setImage(initialImage.fileUrl);
      setImageId(initialImage.id);
    } catch (err) {
      console.log(err);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    fetchImage();
  }, [initialImage]);

  const handleRemoveImage = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setRemoving(true);
    try {
      const res = await getApiData(
        `api/project/delete-files/${imageId}`,
        {},
        'POST',
      );
      if (res.success) {
        setImage(null);
        setImageId(null);
        Notification('success', res.message || 'Image Removed');
      }
    } catch (err) {
      Notification('error', err.message || 'Something went wrong');
    } finally {
      setRemoving(false);
    }
  };

  const handleUpload = async (options) => {
    const { file, onSuccess, onError } = options;
    setProcessing(true);
    try {
      const formData = new FormData();
      formData.append('file_1', file);
      formData.append('project_id', refId);
      formData.append('type', isMilestone ? 'milestone' : 'budgetItemDetails');
      formData.append('tags', isMilestone ? 'Milestone_Image' : null);

      const res = await getApiData(
        'api/project/files',
        formData,
        'POST',
        {
          Accept: 'application/json',
        },
        true,
        false,
        'default',
      );

      if (res.success) {
        onUpload(res);
        setImageId(res.images[0]?.id);
        setImage(res.images[0]?.image || res.images[0]?.url);
        onSuccess(res);
      }
    } catch (err) {
      onError(err);
      Notification('error', err.message || 'Something went wrong');
    } finally {
      setProcessing(false);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      Notification('error', 'You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Notification('error', 'Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setProcessing(true);
      return;
    }
    if (info.file.status === 'done') {
      setProcessing(false);
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {processing ? <LoadingOutlined /> : <PlusOutlined />}
    </button>
  );

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      customRequest={handleUpload}
      disabled={!refId || isOld || imageId}
    >
      {image ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          <img
            src={image}
            alt="milestone-item"
            style={{ height: '-webkit-fill-available', width: '100%' }}
          />
          <Button
            shape="circle"
            size="small"
            icon={<CloseOutlined />}
            style={{ position: 'absolute', top: -8, right: -8 }}
            type="ghost"
            ghost
            danger
            loading={removing}
            disabled={!refId || isOld}
            onClick={handleRemoveImage}
          />
        </div>
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default CRImageUploader;
