import { Divider, Tabs, Typography, Upload } from 'antd';
import styled from 'styled-components';

import Box from '@iso/components/utility/box';

export const Content = styled(Box)`
  flex: 1;
  overflow: auto;
  padding: 0;
`;

export const Image = styled.img`
  width: 128px;
  aspect-ratio: 1;
  object-fit: cover;
`;

export const StyledTabs = styled(Tabs)`
  gap: 10px;
  & .ant-tabs-nav {
    position: sticky !important;
    top: 0;
    background-color: white;
    z-index: 1;
    padding: 12px 24px 0;
  }
  & .ant-tabs-content {
    height: 100%;
    & .ant-tabs-tabpane {
      height: 100%;
    }
  }
`;

export const Root = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px;
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  background-color: white;
`;

export const FooterDivider = styled(Divider)`
  margin: 0 !important;
`;

export const FooterActions = styled.div`
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const Title = styled(Typography)`
  font-weight: bold;
`;

export const UploadDragger = styled(Upload.Dragger)`
  max-height: 154px;
`;
