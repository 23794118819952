import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects';
import getApiData from '@iso/lib/helpers/apiHelper';
import Notification from '@iso/components/Notification';
import { apiEndpoints } from '@iso/config/apiEndpoints';
import {
  ADD_DETAILS,
  SAVE_DRAFT_PROPOSAL,
  saveDraftProposal,
  saveDraftProposalFailure,
  saveDraftProposalLoading,
  saveDraftProposalSuccess,
  saveDetails,
  DELETE_PROPOSAL_IMAGE,
  deleteProposalImageSuccess,
  GET_CONTRACTORS_LIST,
  getContractorsListSuccess,
  ASSIGN_CONTRACTOR,
  assignedContractorsSuccess,
  assignedContractorsFailure,
  assignedContractorsLoading,
  getContractorsListLoading,
  getProjectTypesLoading,
  getProjectTypesFailure,
  getProjectTypesSuccess,
  GET_PROJECT_TYPES,
  GET_PLANS,
  FETCH_PROPOSAL_FILES,
  DELETE_PROJECT_FILE,
  SUBMIT_ASSIGN_CONTRACTOR
} from './actions';

import { transformProposalRequest, transformProposalResponse } from './helpers';

function* addDetailsWorker(action) {
  yield putResolve(saveDetails(action.payload));
  yield put(saveDraftProposal());
}

function* deleteImageWorker(action) {
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.DELETE_PROPOSAL_IMAGE_URL(action.payload),
      {},
      'GET',
    );
    if (res.success) {
      yield put(deleteProposalImageSuccess(action.payload));
      Notification('success', 'Image Deleted');
    }
  } catch (error) {
    Notification('error', 'Something went wrong');
  }
}

function* deleteProjectFileWorker(action) {
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.DELETE_FILE_URL(action.payload.fileId),
      {},
      'POST',
    );
    if (res.success) {
      action.payload.successCallback(res);
    } else {
      action.payload.errorCallback(res);
    }
  } catch (error) {
    action.payload.errorCallback(error);
  }
}

function* getProjectTypesWorker() {
  yield put(getProjectTypesLoading(true));
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.GET_PROJECT_TYPES_URL(),
      {},
      'GET',
    );
    if (res.success) {
      yield put(getProjectTypesSuccess(res.data));
    } else {
      yield put(getProjectTypesFailure(res.message));
    }
  } catch (error) {
    yield put(getProjectTypesFailure(error.message));
  }
}

function* getContractorsListWorker(action) {
  yield put(getContractorsListLoading(true));
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.GET_CONTRACTORS_LIST_URL(action.payload),
      {},
      'GET',
    );
    if (res.success) {
      yield put(
        getContractorsListSuccess({
          data: res.data,
          projectId: action.payload,
        }),
      );
    }
  } catch (error) {
    Notification('Faild to fetch contractors list');
  }
}

function* saveDraftProposalWorker(action) {
  yield put(saveDraftProposalLoading(true));
  try {
    const proposal = yield select((state) => state.Proposal);
    const formData = yield call(transformProposalRequest, proposal);
    const res = yield call(
      getApiData,
      apiEndpoints.SAVE_DRAFT_PROPOSAL_URL(proposal.proposalId),
      formData,
      'POST',
      {},
      true,
      undefined,
    );
    if (res.success) {
      const projectImagesResponse = yield call(
        getApiData,
        apiEndpoints.GET_PROJECT_IMAGES_URL(
          proposal.projectId || res.data.project_id,
        ),
      );

      const transformedProposalResponse = yield call(
        transformProposalResponse,
        {
          ...res,
          ...res.data,
          projectImages: projectImagesResponse.success
            ? projectImagesResponse.data
            : [],
        },
      );

      yield put(
        saveDraftProposalSuccess({
          ...transformedProposalResponse,
          isDone: action.payload,
        }),
      );
    } else {
      yield put(saveDraftProposalFailure(res.message));
    }
  } catch (error) {
    yield put(saveDraftProposalFailure(error.message));
  }
}

function* assignContractorWorker(action) {
  yield put(assignedContractorsLoading(true));
  try {
    const proposal = yield select((state) => state.Proposal);
    const res = yield call(
      getApiData,
      apiEndpoints.SUBMIT_AND_ASSIGN_CONTRACTOR_URL(Number(action.payload.proposal_id)),
      {
        project_id: proposal.projectId.toString(),
        contractor_id: Number(action.payload.assigned_contractor),
        action: action.payload.action
      },
      'POST',
    );
    if (res.success) {
      yield put(assignedContractorsSuccess(res.message));
    } else {
      yield put(assignedContractorsFailure(res.message));
      Notification('error', res.message);
    }
  } catch (error) {
    yield put(assignedContractorsFailure(error.message));
    Notification('error', error.message);
  }
}

function* submitAndAssignContractorWorker(action) {
  yield put(assignedContractorsLoading(true));
  try {
    const proposal = yield select((state) => state.Proposal);
    const res = yield call(
      getApiData,
      apiEndpoints.SUBMIT_AND_ASSIGN_CONTRACTOR_URL(Number(action.payload.proposal_id)),
      {
        project_id: proposal.projectId.toString(),
        contractor_id: Number(action.payload.selectedContractor),
        action: action.payload.action
      },
      'POST',
    );
    if (res.success) {
      yield put(assignedContractorsSuccess(res.message));
    } else {
      yield put(assignedContractorsFailure(res.message));
      Notification('error', res.message);
    }
  } catch (error) {
    yield put(assignedContractorsFailure(error.message));
    Notification('error', error.message);
  }
}


function* getPlans(action) {
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.GET_PLANS(
        action.payload.data.proposalId,
        action.payload.data.price,
        action.payload.data.query,
      ),
      '',
      undefined,
      undefined,
      undefined,
      undefined,
      'alternate',
    );
    console.log(res);
    action.payload.successCallback(res);
  } catch (error) {
    action.payload.errorCallback(error);
  }
}

function* fetchProposalFiles(action) {
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.PROJECT_FILES(action.payload.data.projectId),
      { type: 'proposal', tags: 'LOOK_N_FEEL' },
      undefined,
      undefined,
      undefined,
      undefined,
    );
    console.log(res);
    if (res.success) {
      action.payload.successCallback(res.data);
    } else {
      action.payload.errorCallback(res);
    }
  } catch (error) {
    action.payload.errorCallback(error);
  }
}

export default function* watchFetchData() {
  yield takeLatest(ADD_DETAILS, addDetailsWorker);
  yield takeLatest(SAVE_DRAFT_PROPOSAL, saveDraftProposalWorker);
  yield takeLatest(DELETE_PROPOSAL_IMAGE, deleteImageWorker);
  yield takeLatest(DELETE_PROJECT_FILE, deleteProjectFileWorker);
  yield takeLatest(GET_CONTRACTORS_LIST, getContractorsListWorker);
  yield takeLatest(ASSIGN_CONTRACTOR, assignContractorWorker);
  yield takeLatest(SUBMIT_ASSIGN_CONTRACTOR, submitAndAssignContractorWorker);
  yield takeLatest(GET_PROJECT_TYPES, getProjectTypesWorker);
  yield takeLatest(GET_PLANS, getPlans);
  yield takeLatest(FETCH_PROPOSAL_FILES, fetchProposalFiles);
}
