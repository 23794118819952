import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Notification from '@iso/redux/notification/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Inspections from '@iso/redux/inspections/reducer';
import Proposal from '@iso/redux/proposal/reducer';
import ChangeRequest from '@iso/redux/changeRequest/reducer';

export default combineReducers({
  Auth,
  App,
  LanguageSwitcher,
  Notification,
  Inspections,
  Proposal,
  ChangeRequest,
});
