import styled from 'styled-components';

export const AntFormStyles = styled.div`
  .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-select-selection-placeholder,
  .ant-select .ant-select-selector,
  .ant-input-affix-wrapper:not(
      .ant-input-affix-wrapper-textarea-with-clear-btn
    ),
  .ant-select-selection-item,
  .ant-picker {
    height: 40px !important;
    line-height: 40px !important;
    border-radius: 8px !important;
  }

  .ant-input-affix-wrapper-textarea-with-clear-btn,
  .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
    border-radius: 8px !important;
  }
`;
