export const apiEndpoints = {
  INSPECTIONS_URL: () => '/project/inspections/list',
  INSPECTIONS_URL_V2: () => 'api/v2/project/inspections',
  INSPECTION_URL: () => '/api/v2/project/inspection',
  INSPECTION_BY_ID_URL: (id) => `/api/v2/project/inspection/${id}`,
  UPLOAD_PROJECT_FILES: () => 'api/project/files',
  PROJECT_FILES: (id) => `api/project/files/${id}`,
  PROPOSAL_MILESTONES: (proposalId) =>
    `api/project/list-milestone/${proposalId}`,
  ADD_MILESTONE_CHANGE_REQUEST: () => 'api/v2/change-request/milestone',
  ADD_BUDGET_CHANGE_REQUEST: (milestoneId) =>
    `api/v2/change-request/milestone/${milestoneId}/budget`,
  CHANGE_REQUEST_BY_ID: (changeRequestId) =>
    `api/v2/change-request/${changeRequestId}`,
  SUBMIT_CHANGE_REQUEST: () => 'api/v2/change-request/milestone',
  CHANGE_REQUESTS: (projectId) => `/api/v2/change-request/project/${projectId}`,
  SAVE_DRAFT_PROPOSAL_URL: (proposalId) => {
    const url = 'api/admin/create-draft-proposal';
    if (!proposalId) return url;
    return `${url}?proposal_id=${proposalId}`;
  },
  GET_PROJECT_IMAGES_URL: (projectId) => {
    const url = 'api/project/files';
    return `${url}/${projectId}?type=project`;
  },
  DELETE_FILE_URL: (imageId) => {
    return `api/project/delete-files/${imageId}`;
  },

  DELETE_PROPOSAL_IMAGE_URL: (id) => {
    const url = 'api/user/delete-image';
    if (!id) return null;
    return `${url}/${id}`;
  },
  GET_CONTRACTORS_LIST_URL: (projectId) => {
    const url = 'api/admin/contractor-lists';
    if (!projectId) return url;
    return `${url}?project_id=${projectId}`;
  },
  ASSIGN_CONTRACTOR_URL: () => 'api/admin/submit-draft-to-contractor',
  SUBMIT_AND_ASSIGN_CONTRACTOR_URL: (proposalId) => {
    const url = 'api/admin/proposal';
    return `${url}/${proposalId}/action`;
  },
  GET_PROJECT_TYPES_URL: () => 'api/project/project-list',
  GET_PLANS: (proposalId, amount, query) =>
    `api/v2/paymentlines/proposal/${proposalId}/plan?price=${amount}&${query}`,
};
