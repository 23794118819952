import styled from 'styled-components';
import { Collapse } from 'antd';

export const CollapseStyle1 = styled(Collapse)`
  &.ant-collapse-style1 {
    border-bottom: none !important;
    width: 100%;
    background-color: transparent;
  }

  & .panel-style1 {
    background-color: #f1f3f9;
  }
`;

export const CollapseStyle2 = styled(Collapse)`
  &.ant-collapse-style2 {
    border-bottom: none !important;
    width: 100%;
    background-color: transparent;

    .ant-collapse-item .ant-collapse-header .ant-collapse-extra.collapse-extra-2 {
      min-width: 40%;
    }
  }

  .ant-collapse-content-box {
    margin-left: 40px !important;
  }

  .ant-collapse-header {
    align-items: center !important;
  }
`;
