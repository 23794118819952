const inspectionsStates = {
  due: 'DUE',
  upcoming: 'UPCOMING',
  all: 'ALL',
};
const inspectionStatesOptions = [
  {
    title: 'Due Inspections',
    value: inspectionsStates.due,
  },
  {
    title: 'Upcoming Inspections',
    value: inspectionsStates.upcoming,
  },
  {
    title: 'All',
    value: inspectionsStates.all,
  },
];
export { inspectionsStates, inspectionStatesOptions };
