import styled from 'styled-components';

const RadioButtonWrapper = styled.div`
  .ant-radio-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ant-radio-wrapper {
      flex: 1;
      justify-content: center;
      background-color: #ffffff;

      border: 1px solid #cbcbcb;
      padding: 10px;
      margin-right: 8px;
      border-radius: 7px;

      &:last-child {
        margin-right: 0; // Remove margin for the last item to fit perfectly
      }

      // Change text color when the radio is checked
      &.ant-radio-wrapper-checked {
        border-color: #274bf1;
        color: #274bf1;

        .ant-radio-inner {
          font-size: 15px;
          border-color: #274bf1;
        }
      }
    }
    .ant-radio {
      display: none;
    }
  }
`;
export default RadioButtonWrapper;
