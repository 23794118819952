import React from 'react';
// import { isArray, isEmpty, isObject } from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
// import getApiData from '@iso/lib/helpers/apiHelper';
// import { getDefaultPath } from '@iso/lib/helpers/url_sync';
// const msgObj = {};
const InjectMassage = (props) => {
  // INSERT ALL WORDS INTO DB
  // const preKeys = getDefaultPath();
  // const currentPage = isArray(preKeys) && !isEmpty(preKeys) ? preKeys[0] : '';
  // const main_word = props && props.id ? props.id : '';
  // const trans_word = props && props.intl && main_word ? props.intl.formatMessage({ id: main_word }) : '';

  // console.log('props.intl====>??');
  // console.log(props.intl);
  // if(isObject(props.intl.messages) && !msgObj[main_word]) {
  // 	msgObj[main_word] = trans_word;
  // }
  // console.log('msgObj=====<>?');
  // console.log(msgObj);
  // console.log(JSON.stringify(msgObj))

  // const data = main_word && trans_word ? { word_type: 'Admin', page: currentPage, main_word, trans_word, lang_code: 'en' } : {};
  // console.log(data);
  // Uncomment below code when you want to add all words in DB
  // const sendMainWord = async (param) => {
  // 	try {
  // 		const res = await getApiData('addTrans', param, 'POST');
  // 		if (res.success) {
  // 			console.log('TCL: in if -> res', res);
  // 		} else {
  // 			console.log('TCL: in else -> res', res);
  // 		}
  // 	} catch (error) {
  // 		console.log(error);
  // 	}
  // };
  // if (!isEmpty(data)) {
  // sendMainWord(data);
  // }
  return <FormattedMessage {...props} />;
};
export default injectIntl(InjectMassage, {
  withRef: false,
});
