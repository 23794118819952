import {
  SET_ACTIVE_TAB_KEY,
  SET_CHANGE_REQUEST,
  SET_IS_CHANGE_REQUEST_MODAL_OPEN,
  SET_MILESTONES,
} from '@iso/redux/changeRequest/actions';
import { changeRequestTabsIds } from '@iso/lib/constants/changeRequestTabs';

const initialState = {
  activeTabKey: changeRequestTabsIds['1'],
  changeRequest: {
    details: null,
    newMilestone: null,
    oldMilestone: null,
    newMilestoneBudgets: [],
    oldMilestoneBudgets: [],
    isToFetchChangeRequest: true,
  },
  isChangeRequestModalOpened: false,
  milestones: [],
};

function changeRequestReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_TAB_KEY:
      return {
        ...state,
        activeTabKey: action.payload,
      };
    case SET_CHANGE_REQUEST:
      return {
        ...state,
        changeRequest: { ...state.changeRequest, ...action.payload },
      };
    case SET_IS_CHANGE_REQUEST_MODAL_OPEN:
      return {
        ...state,
        isChangeRequestModalOpen: action.payload,
      };
    case SET_MILESTONES:
      return {
        ...state,
        milestones: action.payload,
      };
    default:
      return state;
  }
}

export default changeRequestReducer;
