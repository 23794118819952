import React from 'react';
import { BoxTitle, BoxSubTitle, BoxHeaderContent } from './boxTitle.style';

export default ({ title, subtitle, rightBtns }) => {
  if (title) {
    return (
      <BoxHeaderContent className="boxHeader">
        <div className="boxTitleMain">
          {title ? <BoxTitle className="isoBoxTitle"> {title} </BoxTitle> : ''}
          {subtitle ? (
            <BoxSubTitle className="isoBoxSubTitle"> {subtitle} </BoxSubTitle>
          ) : (
            ''
          )}
        </div>
        {rightBtns}
      </BoxHeaderContent>
    );
  }
  return null;
};
