import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import appSagas from '@iso/redux/app/sagas';
import inspectionsSagas from '@iso/redux/inspections/sagas';
import proposalSagas from '@iso/redux/proposal/sagas';
import changeRequestSagas from '@iso/redux/changeRequest/sagas';

export default function* rootSaga() {
  yield all([
    authSagas(),
    appSagas(),
    inspectionsSagas(),
    proposalSagas(),
    changeRequestSagas(),
  ]);
}
