import { Col, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import materialUnits from '@iso/lib/constants/materialUnits';

export default function BudgetForm({ prefixFields, ...restProps }) {
  const validateNumberOrDecimal = (_, value) => {
    if (!value || /^\d+(\.\d{1,2})?$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error('Please enter a valid number with up to 2 decimal places'),
    );
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Form {...restProps}>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Budget Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the Budget Name',
              },
            ]}
          >
            <Input placeholder="Enter budget name..." allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Material Type"
            name="materialType"
            dependencies={['manpowerRate', 'days']}
            rules={[
              ({ getFieldValue }) => ({
                required:
                  !getFieldValue('manpowerRate') && !getFieldValue('days'),
                message: 'Please enter the material type',
              }),
            ]}
          >
            <Input placeholder="Marble, Wood, etc..." allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Material Unit"
            name="materialUnit"
            dependencies={['manpowerRate', 'days']}
            rules={[
              ({ getFieldValue }) => ({
                required:
                  !getFieldValue('manpowerRate') && !getFieldValue('days'),
                message: 'Please enter material unit',
              }),
            ]}
          >
            <Select
              style={{ minWidth: 200, width: '100%' }}
              placeholder="Enter material unit..."
              allowClear
            >
              {materialUnits.map((type) => (
                <Select.Option key={type.name} value={type.name}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Quantity"
            name="quantity"
            dependencies={['manpowerRate', 'days']}
            rules={[
              ({ getFieldValue }) => ({
                required:
                  !getFieldValue('manpowerRate') && !getFieldValue('days'),
                message: 'Please enter the quantity',
              }),
              { validator: validateNumberOrDecimal },
            ]}
          >
            <Input type="number" placeholder="Enter quantity..." allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Material unit price"
            name="materialUnitPrice"
            dependencies={['manpowerRate', 'days']}
            rules={[
              ({ getFieldValue }) => ({
                required:
                  !getFieldValue('manpowerRate') && !getFieldValue('days'),
                message: 'Please enter the quantity',
              }),
            ]}
          >
            <Input type="number" placeholder="Enter unit price..." allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Manpower rate"
            name="manpowerRate"
            dependencies={[
              'materialType',
              'materialUnit',
              'materialUnitPrice',
              'quantity',
            ]}
            rules={[
              ({ getFieldValue }) => ({
                required:
                  !getFieldValue('materialUnit') &&
                  !getFieldValue('materialType') &&
                  !getFieldValue('materialUnitPrice') &&
                  !getFieldValue('quantity'),
                message: 'Please enter the manpower rate',
              }),
            ]}
          >
            <Input
              type="number"
              placeholder="Enter manpower rate..."
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Days"
            name="days"
            dependencies={[
              'materialType',
              'materialUnit',
              'materialUnitPrice',
              'quantity',
            ]}
            rules={[
              ({ getFieldValue }) => ({
                required:
                  !getFieldValue('materialUnit') &&
                  !getFieldValue('materialType') &&
                  !getFieldValue('materialUnitPrice') &&
                  !getFieldValue('quantity'),
                message: 'Please enter the days',
              }),
            ]}
          >
            <Input type="number" placeholder="Enter days..." allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item label="Specification" name="specification">
            <TextArea
              type="text"
              placeholder="Write description here..."
              autoSize={{ minRows: 3, maxRows: 5 }}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
