/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import { getDraftTabValue } from '@iso/containers/CreateProposal/config';

export const transformProposalRequest = (proposal) => {
  const {
    details = {},
    milestones,
    milestoneIds,
    budgets,
    paymentGroups,
    paymentGroupIds,
  } = proposal;
  let tempBudgetIndex = 0;
  const budgetFiles = [];

  const data = {
    customer_email: details.customerEmail,
    name: details.projectName,
    username: details.customerName,
    project_type: details.type,
    exp_id: 1,
    description: details.description,
    scope_of_work: details.scopeOfWork,
    proposal: JSON.stringify({
      milestone_details: milestoneIds.map((milestoneId) => {
        const milestone = milestones[milestoneId];
        return {
          payment_group_id: milestone.paymentGroupId ?? '',
          milestone_name: milestone.name,
          description: milestone.description,
          tags: milestone.milestoneType,
          start_date: milestone.startDate,
          end_date: milestone.endDate,
          budget_item: milestone.budgetIds.map((budgetId) => {
            const budget = budgets[budgetId];
            tempBudgetIndex += 1;
            budget.budgetImages.forEach((document) => {
              budgetFiles.push({
                [`budget_image_${tempBudgetIndex}`]: document,
              });
            });
            return {
              budget_id: tempBudgetIndex,
              name: budget.name,
              material_type: budget.materialType,
              material_unit: budget.materialUnit,
              qty: budget.materialQuantity,
              specification: budget.description,
              material_unit_price: budget.materialUnitPrice,
              manpower_rate: budget.manpowerRate,
              days: budget.days,
            };
          }),
        };
      }),
      payment_group_details: paymentGroupIds.map((paymentGroupId) => {
        const paymentGroup = paymentGroups[paymentGroupId];
        return {
          group_id: paymentGroupId,
          group_name: paymentGroup.name,
        };
      }),
    }),
  };

  // converting to formData because multiple items with same key should be sent for project files and budget images.

  const formData = new FormData();

  Object.keys(data).forEach((transformedProposalKey) => {
    formData.append(transformedProposalKey, data[transformedProposalKey]);
  });

  details.projectFiles.forEach((file) => {
    if (!file.isAlreadyUploaded) {
      formData.append('project_image', file.image);
    }
  });

  budgetFiles.forEach((budgetImages) => {
    const fileKey = Object.keys(budgetImages)[0];
    const file = budgetImages[fileKey];
    // if (!file.isAlreadyUploaded) {
    formData.append(fileKey, file.image);
    // }
  });

  return formData;
};

// const urlToFile = async (url, filename) => {
//   console.log("url", url)
//   const response = await fetch(url);
//   console.log("response", response)
//   const buffer = await response.arrayBuffer();
//   return new File([buffer], filename);
// };
const urlToFile = async (url, filename) => {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();
  const mimeType = response.headers.get('content-type');
  return new File([buffer], filename, { type: mimeType });
};

// converting the response to store projectId and proposalId

export const transformProposalResponse = async (proposal) => {
  const {
    project_id,
    proposal_id,
    projectImages = [],
    budget_images = {},
  } = proposal;

  // const projectFiles = await Promise.all(
  //   projectImages.map(async (file, index) => {
  //     console.log("save darft response file", file)
  //     const fileObj = await urlToFile(file.image, `Image${index + 1}`);
  //     console.log("save darft response fileObj", fileObj)
  //     return {
  //       id: file.id,
  //       name: fileObj.name,
  //       image: fileObj,
  //       type: file.file_extention,
  //       // isAlreadyUploaded: true,
  //       url: file.path,
  //     };
  //   }),
  // );

  // const budgetFiles = Object.keys(budgetImages).map((key) => {
  //   return {
  //     id: key,
  //   };
  // });

  const retVal = {
    // projectFiles,
    projectFiles: projectImages,
    budgetImages: budget_images,
    // budgetImages: budgetFiles,
  };

  if (project_id) {
    retVal.projectId = project_id;
  }

  if (proposal_id) {
    retVal.proposalId = proposal_id;
  }

  console.log(retVal, '>>>>>>>>>retVal');

  return retVal;
};

// converting the draft porposal data

export const transformDraftProposalResponse = async (proposal) => {
  console.log(proposal, '>>>>>>proposal');
  const {
    proposal_id,
    project: _details,
    milestones: _milestones,
    budgets: _budgets,
    payment_groups,
  } = proposal;

  const projectFiles = await Promise.all(
    _details.project_image.map(async (file, index) => {
      const fileObj = await urlToFile(file.image, `Image${index + 1}`);
      return {
        id: file.id,
        name: fileObj.name,
        image: fileObj,
        type: file.type,
        isAlreadyUploaded: true,
        url: file.image,
      };
    }),
  );

  const transformBudgetImages = async (images) => {
    return Promise.all(
      images.map(async (file, index) => {
        const fileObj = await urlToFile(file.image, `BudgetImage${index + 1}`);
        return {
          id: file.id,
          name: fileObj.name,
          image: fileObj,
          type: file.type,
          // isAlreadyUploaded: true,
          url: file.image,
        };
      }),
    );
  };

  const milestoneIds = _milestones.map((milestone) => milestone.id);

  const milestones = _milestones.reduce((acc, milestone) => {
    const budgetIds = _budgets.flatMap((budget) => {
      if (budget.milestone_id !== milestone.id) return [];
      return [budget.id];
    });

    const paymentGroup = payment_groups.find((_paymentGroup) => {
      const doesPaymentGroupContainMilestone = _paymentGroup.milestones.some(
        (_milestone) => _milestone.id === milestone.id,
      );
      if (!doesPaymentGroupContainMilestone) return null;
      return _paymentGroup;
    });

    acc[milestone.id] = {
      id: milestone.id,
      name: milestone.milestone_name,
      description: milestone.description,
      milestoneType: milestone.tags,
      budgetIds,
      paymentGroupId: paymentGroup?.group_id ?? null,
      startDate: milestone.start_date,
      endDate: milestone.end_date,
    };
    return acc;
  }, {});

  // const budgets = _budgets.reduce((acc, budget) => {
  //   acc[budget.id] = {
  //     id: budget.id,
  //     name: budget.name,
  //     description: budget.specification,
  //     materialType: budget.material_type,
  //     materialUnit: budget.material_unit,
  //     materialQuantity: budget.qty,
  //     milestoneId: budget.milestone_id,
  //     budgetImages: budget.budget_image,
  //   };
  //   return acc;
  // }, {});
  const budgets = await _budgets.reduce(async (accPromise, budget) => {
    const acc = await accPromise;
    const budgetImages = await transformBudgetImages(budget.budget_image);
    acc[budget.id] = {
      id: budget.id,
      name: budget.name,
      description: budget.specification,
      materialType: budget.material_type,
      materialUnit: budget.material_unit,
      materialUnitPrice: budget.material_unit_price,
      materialQuantity: budget.qty,
      milestoneId: budget.milestone_id,
      manpowerRate: budget.manpower_rate,
      days: budget.days,
      budgetImages,
    };
    return acc;
  }, Promise.resolve({}));

  const paymentGroupIds = payment_groups.map(
    (paymentGroup) => paymentGroup.group_id,
  );

  const paymentGroups = payment_groups.reduce((acc, paymentGroup) => {
    const paymentGroupMilestoneIds = paymentGroup.milestones.map(
      (milestone) => milestone.id,
    );
    acc[paymentGroup.group_id] = {
      id: paymentGroup.group_id,
      name: paymentGroup.group_name,
      milestoneIds: paymentGroupMilestoneIds,
    };

    return acc;
  }, {});

  return {
    activeTabId: getDraftTabValue(proposal.tab_flag),
    projectId: _details.id,
    proposalId: proposal_id,
    details: {
      type: _details.project_type,
      projectName: _details.name,
      description: _details.description,
      customerName: _details.username,
      customerEmail: _details.customer_email,
      scopeOfWork: _details.scope_of_work,
      projectFiles,
    },
    milestones,
    milestoneIds,
    budgets,
    paymentGroups,
    paymentGroupIds,
  };
};
