import { call, put, takeLatest } from 'redux-saga/effects';
import getApiData from '@iso/lib/helpers/apiHelper';
import { apiEndpoints } from '@iso/config/apiEndpoints';
import {
  FETCH_INSPECTIONS,
  FETCH_INSPECTION,
  SUBMIT_INSPECTION,
  FETCH_INSPECTION_FILES,
  fetchDataSuccess,
  fetchDataFailure,
  setDataLoading,
  setInspectionUnderSubmission,
  setSubmitLoading,
} from './actions';

function* fetchInspections(action) {
  try {
    yield put(setDataLoading(true));
    const res = yield call(
      getApiData,
      apiEndpoints.INSPECTIONS_URL_V2(),
      action.payload,
      undefined,
      undefined,
      undefined,
      undefined,
      'alternate',
    );
    console.log(res);
    // if (res.success) {
    // set dataLoading value as false
    yield put(fetchDataSuccess(res));
    // } else {
    //   yield put(fetchDataFailure(res.message));
    // }
  } catch (error) {
    // set dataLoading value as false
    yield put(fetchDataFailure(error));
  }
}

function* fetchInspection(action) {
  try {
    if (action.payload.id) {
      const res = yield call(
        getApiData,
        apiEndpoints.INSPECTION_BY_ID_URL(action.payload.id),
        action.payload.id,
        undefined,
        undefined,
        undefined,
        undefined,
        'alternate',
      );
      console.log('get inspection by id', res);
      action.payload.successCallback();
      yield put(setInspectionUnderSubmission(res));
      // yield put(setInspectionUploadDrawerLoading(false));
      // yield put(setDetailsDrawerLoading(false));
    }
  } catch (error) {
    action.payload.errorCallback();
    // yield put(fetchDataFailure(error));
    // yield put(setInspectionUploadDrawerLoading(false));
    // yield put(setDetailsDrawerLoading(false));
  }
}

function* SubmitInspection(action) {
  yield put(setSubmitLoading(true));
  try {
    yield call(
      getApiData,
      apiEndpoints.INSPECTION_URL(),
      action.payload.data,
      'PUT',
      undefined,
      undefined,
      undefined,
      'alternate',
    );
    action.payload.successCallback();
    // yield put(setSubmitLoading(false));
  } catch (error) {
    // yield put(fetchDataFailure(error));
    action.payload.errorCallback();
    // yield put(setSubmitLoading(false));
  }
}

function* fetchInspectionFiles(action) {
  try {
    const res = yield call(
      getApiData,
      apiEndpoints.PROJECT_FILES(action.payload.query.projectId),
      { type: 'inspection' },
      undefined,
      undefined,
      undefined,
      undefined,
    );
    console.log(res);
    if (res.success) {
      action.payload.successCallback(res.data);
    } else {
      action.payload.errorCallback(res);
    }
  } catch (error) {
    action.payload.errorCallback(error);
  }
}

export default function* watchFetchData() {
  yield takeLatest(FETCH_INSPECTIONS, fetchInspections);
  yield takeLatest(SUBMIT_INSPECTION, SubmitInspection);
  yield takeLatest(FETCH_INSPECTION, fetchInspection);
  yield takeLatest(FETCH_INSPECTION_FILES, fetchInspectionFiles);
}
