export const SET_FILTERS = 'SET_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const FETCH_INSPECTIONS = 'FETCH_INSPECTIONS';
export const SUBMIT_INSPECTION = 'SUBMIT_INSPECTION';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const SET_DATA_LOADING = 'SET_DATA_LOADING';
export const SET_INSPECTION_DRAWER_LOADING = 'SET_INSPECTION_DRAWER_LOADING';
export const SET_SUBMIT_LOADING = 'SET_SUBMIT_LOADING';
export const SET_DETAILS_DRAWER_LOADING = 'SET_DETAILS_DRAWER_LOADING';
export const RESET_PAGINATION = 'RESET_PAGINATION';
export const TOGGLE_INSPECTION_UPLOAD_DRAWER =
  'TOGGLE_INSPECTION_UPLOAD_DRAWER';
export const TOGGLE_INSPECTION_DETAILS_DRAWER =
  'TOGGLE_INSPECTION_DETAILS_DRAWER';
export const SET_INSPECTION_UNDER_SUBMISSION =
  'SET_INSPECTION_UNDER_SUBMISSION';
export const FETCH_INSPECTION = 'FETCH_INSPECTION';
export const FETCH_INSPECTION_FILES = 'FETCH_INSPECTION_FILES';
export const RESET_STATE = 'RESET_STATE';

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: filters,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});

export const resetPagination = () => ({
  type: RESET_PAGINATION,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const setPagination = (pagination) => ({
  type: SET_PAGINATION,
  payload: pagination,
});

export const setDataLoading = (loading) => ({
  type: SET_DATA_LOADING,
  payload: loading,
});

export const setInspectionUploadDrawerLoading = (loading) => ({
  type: SET_INSPECTION_DRAWER_LOADING,
  payload: loading,
});

export const setDetailsDrawerLoading = (loading) => ({
  type: SET_DETAILS_DRAWER_LOADING,
  payload: loading,
});

export const setSubmitLoading = (loading) => ({
  type: SET_SUBMIT_LOADING,
  payload: loading,
});

export const fetchInspections = (query) => ({
  type: FETCH_INSPECTIONS,
  payload: query,
});

export const fetchInspectionFiles = ({
  query,
  successCallback,
  errorCallback,
}) => ({
  type: FETCH_INSPECTION_FILES,
  payload: { query, successCallback, errorCallback },
});

export const fetchInspection = ({ id, successCallback, errorCallback }) => ({
  type: FETCH_INSPECTION,
  payload: { id, successCallback, errorCallback },
});

export const submitInspection = ({ data, successCallback, errorCallback }) => ({
  type: SUBMIT_INSPECTION,
  payload: { data, successCallback, errorCallback },
});

export const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

export const toggleInspectionUploadDrawer = (value) => ({
  type: TOGGLE_INSPECTION_UPLOAD_DRAWER,
  payload: value,
});

export const toggleInspectionDetailsDrawer = (value) => ({
  type: TOGGLE_INSPECTION_DETAILS_DRAWER,
  payload: value,
});

export const setInspectionUnderSubmission = (value) => ({
  type: SET_INSPECTION_UNDER_SUBMISSION,
  payload: value,
});
