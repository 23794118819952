export const SET_ACTIVE_TAB_KEY = 'SET_ACTIVE_TAB_KEY';
export const FETCH_PROPOSAL_MILESTONES = 'FETCH_PROPOSAL_MILESTONES';
export const ADD_NEW_MILESTONE = 'ADD_NEW_MILESTONE';
export const ADD_MILESTONE_BUDGET = 'ADD_MILESTONE_BUDGET';
export const SET_CHANGE_REQUEST = 'SET_CHANGE_REQUEST';
export const FETCH_CHANGE_REQUEST = 'FETCH_CHANGE_REQUEST';
export const SUBMIT_CHANGE_REQUEST = 'SUBMIT_CHANGE_REQUEST';
export const FETCH_CHANGE_REQUESTS = 'FETCH_CHANGE_REQUESTS';
export const SET_IS_CHANGE_REQUEST_MODAL_OPEN =
  'SET_IS_CHANGE_REQUEST_MODAL_OPEN';
export const SET_MILESTONES = 'SET_MILESTONES';

export const setActiveTabKey = (key) => ({
  type: SET_ACTIVE_TAB_KEY,
  payload: key,
});

export const fetchProposalMilestones = ({
  proposalId,
  successCallback,
  errorCallback,
}) => ({
  type: FETCH_PROPOSAL_MILESTONES,
  payload: { proposalId, successCallback, errorCallback },
});

export const addNewMilestone = ({ data, successCallback, errorCallback }) => ({
  type: ADD_NEW_MILESTONE,
  payload: { data, successCallback, errorCallback },
});

export const addMilestoneBudget = ({
  data,
  milestoneId,
  successCallback,
  errorCallback,
}) => ({
  type: ADD_MILESTONE_BUDGET,
  payload: { data, milestoneId, successCallback, errorCallback },
});

export const fetchChangeRequest = ({
  changeRequestId,
  successCallback,
  errorCallback,
}) => ({
  type: FETCH_CHANGE_REQUEST,
  payload: { changeRequestId, successCallback, errorCallback },
});

export const fetchChangeRequests = ({
  projectId,
  successCallback,
  errorCallback,
}) => ({
  type: FETCH_CHANGE_REQUESTS,
  payload: { projectId, successCallback, errorCallback },
});

export const submitChangeRequest = ({
  data,
  successCallback,
  errorCallback,
}) => ({
  type: SUBMIT_CHANGE_REQUEST,
  payload: { data, successCallback, errorCallback },
});

export const setChangeRequest = (value) => ({
  type: SET_CHANGE_REQUEST,
  payload: value,
});
export const setIsChangeRequestModalOpen = (value) => ({
  type: SET_IS_CHANGE_REQUEST_MODAL_OPEN,
  payload: value,
});
export const setMilestones = (milestones) => ({
  type: SET_MILESTONES,
  payload: milestones,
});
