export default [
  { name: 'tonns', label: 'tonns' },
  { name: 'Kg', label: 'Kg' },
  { name: 'g', label: 'g' },
  { name: 'lbs', label: 'lbs' },
  { name: 'litre', label: 'litre' },
  { name: 'ml', label: 'ml' },
  { name: 'sqm', label: 'sqm' },
  { name: 'meter', label: 'meter' },
  { name: 'item', label: 'item' },
];
