const theme = {};

export const primaryColor = '#274BF1';

theme.palette = {
  primary: [
    primaryColor, // 0: Default
    '', // 1: Darken 4%
    '', // 2: Darken 5%
    'rgba(47, 84, 235, 0.2)', // 3: Fade 20%
    '', // 4: Lighten 3%
    'rgba(47, 84, 235, 0.75)', // 5: Fade 75%
  ],
  secondary: [
    '#202939', // 0: DarkBlue
    '#f1f3f6', // 1: LightBluish
    '#676e7f', // 2: LightBlue
    '#E4E6E9', // 3: LightBluish Darken 5%
    '#364d79', // 4:
    '#202739', // 5: DarkBlue Darken 5%
    '#f5f6f8', // 6: LighterBluish
    '#e9ebf1', // 7: DarkBluish
    '#F6F8FB', // 8: LighterBluish Lighten 2%
    '#E9EBEE', // 9: LighterBluish Darken 3%
    '#1a1a1a', // 10: Sidebar submenu select
  ],
  warning: [
    '#ffbf00', // 0: Warning
  ],
  success: [
    '#00b16a', // 0: Success
  ],
  error: [
    '#f64744', // 0: Error
    '#EC3D3A', // 1: Darken 4%
    '#FF5B58', // 2: Lighten 8%
  ],
  grayscale: [
    '#bababa', // 0: GreyShade
    '#c1c1c1', // 1: GreyDark
    '#D8D8D8', // 2: Grey
    '#f1f1f1', // 3: GreyAlt
    '#F3F3F3', // 4: GreyLight
    '#fafafa', // 5: DarkWhite
    '#F9F9F9', // 6: DarkerWhite
    '#fcfcfc', // 7: #fff Darken 1%
    '#eeeeee', // 8:
    '#fbfbfb', // 9:
    '#f5f5f5', // 10:
    '#f7f8f9', // 11: today-highlight-bg
  ],
  text: [
    '#323332', // 0: Heading
    '#595959', // 1: HeadingLight
    '#979797', // 2: Text
    '#797979', // 3: TextDark
    '#6a6c6a', // 4: Heading Lighten 22%
    '#ffffff', // 5: White
    '#4f535d', // 5: Dark Blue
  ],
  border: [
    '#e9e9e9', // 0: Border
    '#d9d9d9', // 1: BorderDark
    '#ebebeb', // 2: BorderLight
    '#d3d3d3', // 3:
    'rgba(228, 228, 228, 0.65)', // 4:
  ],
};

theme.fonts = {
  primary: 'ElMessiri',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

theme.colors = {
  primaryColor,
};

export default theme;
