import React from 'react';
import BoxTitleWrapper from './boxTitle';
import { BoxWrapper } from './box.style';

export default ({ className, title, subtitle, children, style, rightBtns }) => {
  return (
    <BoxWrapper className={`${className || ''} isoBoxWrapper`} style={style}>
      <BoxTitleWrapper
        title={title}
        subtitle={subtitle}
        rightBtns={rightBtns}
      />
      {children}
    </BoxWrapper>
  );
};
