import { StyledExtra } from '@iso/assets/styles/budgetItem.styles';
import { Button, Space, Tag } from 'antd';
import moment from 'moment';
import { formatAmount, formatEmptyValue } from '@iso/lib/helpers/utility';
import { changeRequestStatuses } from '@iso/lib/constants/changeRequestTabs';
import BudgetItemV2 from '@iso/components/Proposal/BudgetItemV2';
import React from 'react';
import CRImageUploader from '@iso/containers/AllProjects/_components/CRImageUploader';

const ChangeRequestExtra = ({
  changeRequestDetails,
  isEdit = true,
  handleEdit,
}) => {
  return (
    <StyledExtra className="collapse-extra-2">
      <Space style={{ justifyContent: 'end', width: '100%' }}>
        <Space style={{ minWidth: '32ch' }}>
          <h3 className="info-title">New Move-in Date:</h3>
          <p className="info-value">
            {formatEmptyValue(changeRequestDetails?.newMoveInDate, '', 'date')}
          </p>
        </Space>
        <Space style={{ minWidth: '25ch' }}>
          <h3 className="info-title">Price:</h3>
          <p className="info-value" style={{ textAlign: 'end' }}>
            {formatAmount(changeRequestDetails?.changeRequestPrice)}
            <span style={{ fontSize: '10px', marginLeft: '4px' }}>AED</span>
          </p>
        </Space>
        <Space style={{ minWidth: '25ch' }}>
          <h3 className="info-title">Cost:</h3>
          <p className="info-value" style={{ textAlign: 'end' }}>
            {formatAmount(changeRequestDetails?.changeRequestCost)}
            <span style={{ fontSize: '10px', marginLeft: '4px' }}>AED</span>
          </p>
        </Space>
        {isEdit ? (
          <Button style={{ width: '8ch' }} type="primary" onClick={handleEdit}>
            Edit
          </Button>
        ) : (
          <div style={{ width: '8ch' }} />
        )}
      </Space>
    </StyledExtra>
  );
};

const ChangeRequestHeader = ({ milestone, status }) => (
  <Space
    style={{
      justifyContent: 'flex-start',
      rowGap: '20px',
      columnGap: '20px',
      width: '100%',
    }}
  >
    <h2 className="ant-collapse-title">{milestone.milestoneName}</h2>
    <Tag
      color={
        changeRequestStatuses[status].color ||
        changeRequestStatuses.AWAITING_ADMIN_CONFIRMATION.color
      }
    >
      {changeRequestStatuses[status].label}
    </Tag>
  </Space>
);

const renderMilestoneDetails = (milestoneDetails, title, isOld) => (
  <div style={{ marginBottom: '16px' }}>
    <Space>
      <h3 style={{ fontWeight: 700, marginBottom: '10px' }}>{title}</h3>
    </Space>
    <Space direction="vertical" style={{ width: '100%' }}>
      <div className="d-flex gap-10">
        <div style={{ width: '128px' }}>
          <CRImageUploader
            refId={milestoneDetails.milestoneId}
            isMilestone
            initialImage={milestoneDetails.file}
            onUpload={() => {}}
            isOld={isOld}
          />
        </div>
        <div
          className="grow d-flex"
          style={{
            alignItems: 'start',
            flexWrap: 'wrap',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Space>
            <h3 className="info-title">Milestone Name:</h3>
            <p className="info-value">{milestoneDetails.milestoneName}</p>
          </Space>
          <div
            className="grow d-flex"
            style={{
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: 'start',
              gap: '18px',
            }}
          >
            <Space>
              <h3 className="info-title">End Date:</h3>
              <p className="info-value">
                {moment(milestoneDetails.endDate).format('MMM DD, YYYY')}
              </p>
            </Space>
            <Space>
              <h3 className="info-title">Amount:</h3>
              <p className="info-value">
                {formatAmount(milestoneDetails?.amount)}
                <span style={{ fontSize: '10px', marginLeft: '4px' }}>AED</span>
              </p>
            </Space>
            <Space>
              <h3 className="info-title">Start Date:</h3>
              <p className="info-value">
                {moment(milestoneDetails.startDate).format('MMM DD, YYYY')}
              </p>
            </Space>
          </div>
          <Space>
            <h3 className="info-title">Description:</h3>
            <p className="info-value" style={{ overflowWrap: 'anywhere' }}>
              {milestoneDetails.description ?? '-'}
            </p>
          </Space>
        </div>
      </div>
    </Space>
  </div>
);

const renderBudgetItems = (budgetItems) => (
  <>
    {budgetItems.map((item) => (
      <BudgetItemV2
        key={item.proposalBudgetId}
        budget={item}
        showMenu={false}
        isChangeRequest
      />
    ))}
  </>
);

export {
  ChangeRequestExtra,
  ChangeRequestHeader,
  renderMilestoneDetails,
  renderBudgetItems,
};
