import siteConfig from '@iso/config/site.config';
import { omitBy, isNull, isUndefined, isEmpty } from 'lodash';
import { getToken, getLanguage, getCountry } from '@iso/lib/helpers/utility';
import Notification from '@iso/components/Notification';
import authAction from '@iso/redux/auth/actions';
import appActions from '@iso/redux/app/actions';
import { store } from '@iso/redux/store';

const defaultHeader = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

function apiCall(
  url,
  data = {},
  methodParm = 'GET',
  heads = defaultHeader,
  formData = false,
  fetchCommonData = false,
  baseType = 'default',
) {
  const headers = { ...heads };
  const method = methodParm.toUpperCase();
  const token = getToken();
  const lang = getLanguage();
  const country = getCountry();
  const cId = country && country.id ? country.id : '';

  if (token && !headers.Authorization)
    headers.Authorization = `Bearer ${token}`;

  headers.language = lang || 'en';

  let options = {
    method,
    headers,
  };
  let query = '';
  let qs = '';

  const apiData = !formData
    ? omitBy(data, (v) => isUndefined(v) || isNull(v))
    : data;

  if (cId && cId !== 'all') {
    // apiData.country = cId;
    headers.country = cId || '';
  }

  const dataLength = apiData ? Object.keys(apiData).length : 0;
  const body = formData ? apiData : JSON.stringify(apiData);

  if (method === 'POST' || method === 'PUT' || method === 'DELETE')
    options = { ...options, body };
  if (method === 'GET' && dataLength > 0) {
    Object.keys(apiData).map((key, i) => {
      const sep = i === dataLength - 1 ? '' : '&';
      query += `${encodeURIComponent(key)}=${encodeURIComponent(
        apiData[key],
      )}${sep}`;
    });
    qs = `?${query}`;
  }
  const baseUrl =
    baseType === 'alternate' ? siteConfig.alternateApiUrl : siteConfig.apiUrl;
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}${url}${qs}`, options)
      .then((response) =>
        response.text().then((text) => ({
          status: response.status,
          statusText: response.statusText,
          body: text,
        })),
      )
      .then(({ status, statusText, body }) => {
        const responseJson = body ? JSON.parse(body) : {};
        if (status >= 200 && status < 300) {
          resolve(responseJson);
          const cmData = store ? store.getState().App.commonData : {};
          if (fetchCommonData || isEmpty(cmData)) {
            store.dispatch(appActions.setCommonData());
          }
        } else {
          if (token && [401, 403, 404].includes(status) && store) {
            const auth = store ? store.getState().Auth : {};
            store.dispatch(authAction.setLoggedOut(true));
            if (!auth.loggedOut) {
              Notification('error', 'Session expired, Please login again');
              setTimeout(() => {
                store.dispatch(authAction.logout());
              }, 500);
            }
          }
          reject({ status, statusText, body: responseJson });
        }
      })
      // .then((text) => {
      //   const responseJson = text ? JSON.parse(text) : {};
      //   if (
      //     responseJson &&
      //     token &&
      //     [401, 403, 404].includes(responseJson.code) &&
      //     store
      //   ) {
      //     const auth = store ? store.getState().Auth : {};
      //     store.dispatch(authAction.setLoggedOut(true));
      //     if (!auth.loggedOut) {
      //       Notification('error', 'Session expired, Please login again');
      //       setTimeout(() => {
      //         store.dispatch(authAction.logout());
      //       }, 500);
      //     }
      //   } else {
      //     resolve(responseJson);
      //     const cmData = store ? store.getState().App.commonData : {};
      //     if (fetchCommonData || isEmpty(cmData)) {
      //       store.dispatch(appActions.setCommonData());
      //     }
      //   }
      // })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export default apiCall;
